import request from '@/utils/requestV2'
const qs = require('qs')

// 分页获取媒体商城订单列表
export function getMerchantOrderPage (data) {
  return request({
    url: '/ooh-life/mediaMall/order/getMerchantOrderPage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取订单详情
export function getOrderDetail (data) {
  return request({
    url: '/ooh-life/mediaMall/order/getOrderDetail',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 订单退款
export function orderRefund (data) {
  return request({
    url: '/ooh-life/mediaMall/order/orderRefund',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 订单退款记录
export function getOrderRefundList (data) {
  return request({
    url: '/ooh-life/mediaMall/order/getOrderRefundList',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 订单核销
export function virtualTicketNo (data) {
  return request({
    url: '/ooh-life/mediaMall/order/virtualTicketNo',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 分页获取退款列表
export function getRefundPage (data) {
  return request({
    url: '/ooh-life/mediaMall/refund/getRefundPage',
    method: 'post',
    data: qs.stringify(data)
  })
}
