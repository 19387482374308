<template>
    <div>
        <detailComponent :versionId="id"/>
    </div>
</template>

<script>
import detailComponent from '@/components/statementChange/DetailComponent'

export default {
  props: {
    id: [Number, String]
  },
  components: {
    detailComponent
  }
}
</script>
