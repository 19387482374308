<template>
  <div>
    <Row class="text-right m-b-5">
      <i-button size="small" type="warning" @click="handleDownload">下载服务费详单</i-button>
    </Row>

    <i-table  stripe :data="serviceFees" :columns="columns"></i-table>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { GetPublishDay, GetCurrentSchedule } from '@/utils/dateFormat'
import { downloadFileRequest } from '@/utils/download'

export default {
  props: {
    serviceFees: {
      type: Array,
      required: true
    },
    statementBean: {
      type: Object,
      required: true
    }

  },
  data () {
    return {
      columns: [
        { title: '类型', key: 'feeTypeName' },
        {
          title: '发布档期',
          render: (h, data) => {
            return h('div', [
              h('span', GetCurrentSchedule(data.row.startDate, data.row.endDate)),
              h('span', { style: { color: '#ed4014' } }, '(' + GetPublishDay(data.row.startDate, data.row.endDate) + ')')
            ])
          }
        },
        {
          title: '金额',
          align: 'right',
          render: (h, data) => {
            return h('span', {}, toMoney(data.row.amount))
          }
        },
        { title: '付款时间', key: 'updateTime' }
      ]
    }
  },
  methods: {
    handleDownload () {
      let filename = '结算单服务费补充协议('
      filename += this.statementBean.advertiserName
      filename += this.statementBean.code
      filename += ').doc'

      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/statement/downloadannex', { statementId: this.statementBean.statementId }, filename)
    }
  }

}
</script>
