<style scoped>
.lable-title{
    width: 80px;
    display: inline-block;
}
</style>

<template>
    <div>
        <h2 class="text-center">
            罚款
            <tag v-if="besicInfo.feeType === 52" color="blue" type="border">供应商罚单</tag>
            <tag v-else color="green" type="border">代理商罚单</tag>
        </h2>
        <Card :bordered="false" dis-hover>
            <h4 slot="title">基础信息</h4>
            <Row class="p-b-5">
                <i-col span="12" style="word-break: break-all;">
                    <label class="title m-r-5">名称</label>
                    {{besicInfo.title}}
                </i-col>
                <i-col span="12">
                    <label class="title m-r-5">状态</label>
                    <span v-if="besicInfo.status===4">{{besicInfo.statusName}}</span>
                     <Tag v-else :color="getStatusColor(besicInfo.status)">{{besicInfo.statusName}}</Tag>
                </i-col>
            </Row>

            <Row class="p-b-5">
                <i-col span="12">
                    <label class="title m-r-5">发起人</label>
                    {{besicInfo.createUserName}}
                </i-col>
                <i-col span="12">
                    <label class="title m-r-5">提交时间</label>
                    {{besicInfo.createTime}}
                </i-col>
            </Row>

            <Row class="p-b-5">
                <i-col span="12">
                    <label class="title m-r-5">代理商</label>
                    {{besicInfo.agentCompanyName}}
                </i-col>
                <i-col span="12">
                    <label class="title m-r-5">供应商</label>
                    {{besicInfo.supplierCompanyName}}
                </i-col>
            </Row>

            <Row class="p-b-5">
                <i-col span="12">
                    <label class="title m-r-5">罚款金额</label>
                    <span class="text-red text-16">{{formatMoney(besicInfo.amount)}}</span>
                </i-col>
                <i-col span="12">
                    <label class="title m-r-5">罚款日期</label>
                    {{besicInfo.penaltyDate}}
                </i-col>
            </Row>

            <p class="p-b-5"  style="word-break: break-all;">
                <label class="title m-r-5">描述</label>
                {{besicInfo.content}}
            </p>
        </Card>

        <Card :bordered="false" dis-hover>
          <h4 slot="title">上传文件清单</h4>
          <i-table stripe  :columns="approvalFileColumn" :data="approvalFile" size="small"></i-table>
        </Card>
    </div>
</template>

<script>
import { downloadFileRequest } from '@/utils/download'
import { getPenalty } from '@/api/statement/penalty'
import { toMoney } from '@/utils/wnumb_own'

export default {
  props: {
    id: [Number, String],
    task: {
      type: Object
    }
  },
  // inject: ['reload'],
  data () {
    return {
      besicInfo: {},
      approvalFile: [],
      approvalFileColumn: [
        {
          title: '文件缩略图',
          render: (h, data) => {
            if (data.row.mimeType.startsWith('image')) {
              return h('Icon', {
                props: { type: 'md-images', size: 28, color: '#e96900' },
                style: { 'margin-left': '8px' }
              })
            } else if (this.isMSWord(data.row)) {
              return h('img', { attrs: { src: require('@/assets/images/WinWordLogoSmall.scale-180.png'), height: '30px' } })
            } else if (this.isMSExcel(data.row)) {
              return h('img', { attrs: { src: require('@/assets/images/ExcelLogoSmall.scale-180.png'), height: '30px' } })
            }
          }
        },
        { title: '文件名称', key: 'fileName' },
        { title: '文件大小 (kb)', key: 'length' },
        {
          title: '下载',
          render: (h, params) => {
            return h('a',
              {
                style: { fontSize: '20px' },
                on: {
                  click: () => {
                    this.downloadFile(params.row)
                  }
                }
              },
              [h('Icon', { props: { type: 'md-cloud-download' } })]
            )
          }
        }
      ]
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      const query = { penaltyId: this.id }
      getPenalty(query).then(res => {
        this.besicInfo = res
        this.approvalFile = res.ossfileList
      })
    },
    getStatusColor (result) {
      let color = 'Gray'
      if (result === 5) { // 已通过
        color = '#44bd32'
      } else if (result === 6) { // 已拒绝
        color = '#44bd32'
      }

      return color
    },
    formatMoney (number) {
      return toMoney(number)
    },
    downloadFile (file) {
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-statement/v1/penalty/downloadpenaltyfile', { fileId: file.id }, file.fileName)
    },
    isMSWord (file) {
      const mimeTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword']
      return mimeTypes.includes(file.mimeType)
    },
    isMSExcel (file) {
      const mimeTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
      return mimeTypes.includes(file.mimeType)
    }
  }
}
</script>
