<template>
  <div>
    <statement-detail   :id="id" :versionId="id" :isApproval="true"/>
    <!-- <Card v-if="versionId !== 0" :bordered="false" dis-hover>
      <p slot="title">上传的附件</p>
      <files type="STATEMENT_ATTACHMENT_FILE" :relateId="versionId"></files>
    </Card> -->
  </div>
</template>

<script>
import statementDetail from '@/components/statement/StatementDetailComponent'
// import files from '@/components/common/Files'
// import { getStatementDetail } from '@/api/scp/statement'

export default {
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  components: {
    statementDetail
  },
  data () {
    return {
      dataEntity: null,
      versionId: 0
    }
  },
  created () {
    // getStatementDetail({ statementId: this.id }).then(res => {
    //   this.versionId = res.versionId // 结算单版本号
    //   this.dataEntity = res
    // })
  }
}
</script>
