<template>
  <div>
    <Card class="m-b-10 m-t-10">
        <p slot="title">合同概况</p>
        <p slot="extra">
          <Tag type="border" color="blue">{{contractBasicInfo.statusName}}</Tag>
        </p>
        <Row>
          <i-col span="6" class="m-b-10">
                <span  class="title">合同类型 </span> {{contractBasicInfo.typeName}}
            </i-col>
            <i-col span="6" class="m-b-10">
                <span  class="title">合同编码 </span> {{contractBasicInfo.code}}
            </i-col>
            <i-col span="6" class="m-b-10">
                <span  class="title">合同名称 </span> {{contractBasicInfo.name}}
            </i-col>
            <i-col span="6" class="m-b-10">
                  <span class="title">客户名称 </span>  {{contractBasicInfo.businessName}}
            </i-col>

                <i-col span="6" class="m-b-10" v-if="contractBasicInfo.type===2">
                  <span  class="title">合作期限 </span> {{contractBasicInfo.startDate}}至 {{contractBasicInfo.endDate}}
              </i-col>
              <i-col span="6" class="m-b-10" v-if="contractBasicInfo.type===2">
                    <span class="title">保证金 </span>  {{contractBasicInfo.formatDepositAmount}}
              </i-col>
              <i-col span="12" class="m-b-10" v-if="contractBasicInfo.type===2">
                    <span class="title">服务费计算方式 </span> {{contractBasicInfo.platformFeeType===1?'按已完成交易商品或服务的零售价格的【'+contractBasicInfo.platformFeeRate+'%】计算':'乙方仅按已完成交易商品或服务的结算价格收取货款，零售溢价部分为平台服务费'}}
              </i-col>

            <i-col span="6" class="m-b-10">
                <span  class="title">签约时间 </span> {{contractBasicInfo.signDate}}
            </i-col>
            <i-col span="6" class="m-b-10">
                  <span class="title">创建时间 </span>  {{contractBasicInfo.createTime}}
            </i-col>
            <i-col span="6" class="m-b-10">
                  <span class="title">创建人 </span>  {{contractBasicInfo.userName}}
            </i-col>
        </Row>
    </Card>

    <Tabs size="small">
      <TabPane label="详细信息">
        <p class="p-b-5 p-t-20 workplatform-title">商品清单</p>
        <i-table stripe size="small" :data="commodityList" :columns="commodityColumns"></i-table>
        <!-- 仅代销合同允许添加商品 -->
        <p v-if="contractBasicInfo.type===2 && allowEdit" class="m-t-5 text-right">
          <a @click="handleAddCommodity"><Icon type="md-add" /> 新增商品</a>
        </p>
        <div v-if="contractBasicInfo.type===1">
          <p class="p-b-5 p-t-20 workplatform-title">采购媒体折扣概览</p>
        <i-table stripe size="small" :data="displaceMediaTypeList" :columns="displaceMediaTypeColumns"></i-table>
        <p class="p-b-5 p-t-20 workplatform-title">服务费采购概览</p>
        <i-table stripe size="small" :data="displaceServiceList" :columns="displaceServiceColumns"></i-table>
        <p class="p-b-5 p-t-20 workplatform-title">采购媒体清单</p>
        <i-table stripe size="small" :data="displaceMediaList" :columns="displaceMediaColumns"></i-table>
        </div>

      </TabPane>
      <TabPane label="附件">
        <i-table stripe size="small" :data="fileData" :columns="fileColumns"></i-table>
        <Upload v-if="allowEdit" class="m-t-10" :action="uploadUrl" :show-upload-list="false"
          :headers="uploadHeaders" :data="uploadParams" :on-success="loadUpdateFiles">
          <Button type="success" icon="ios-cloud-upload-outline">点击上传附件</Button>
        </Upload>
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { getStorage } from '@/utils/storage'
import { downloadFileRequest } from '@/utils/download'
import {
  getContractById, listContractDetail, listOrderDetail, listDiscount,
  deleteContractFile, listContractFile, deleteProduct, listService
} from '@/api/displace/contract'

export default {
  props: {
    id: [Number, String],
    allowEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      contractBasicInfo: {},

      commodityList: [],
      commodityColumns: [
        { title: '商品名称', key: 'name' },
        { title: '商品类型', key: 'productTypeName', width: 120 },
        { title: '商品编码', key: 'code' },
        {
          title: '门市价',
          width: 120,
          render: (h, data) => {
            return h('div', [
              h('span', { class: 'money' }, this.formatMoney(data.row.useAmount))
            ])
          }
        },
        {
          renderHeader: (h, data) => {
            return h('div', [
              h('span', this.contractBasicInfo.type === 1 ? '置换价' : this.contractBasicInfo.platformFeeType === 1 ? '零售价' : '结算价')
            ])
          },
          width: 120,
          render: (h, data) => {
            return h('div', [
              h('span', { class: 'money' }, this.formatMoney(data.row.amount))
            ])
          }
        },
        { title: '数量', key: 'number', width: 100 },
        {
          title: '核销期',
          key: 'schedule',
          render: (h, data) => {
            return h('div', [
              h('span', data.row.validityStartDate),
              h(
                'span',
                ' 至 '
              ),
              h('span', data.row.validityEndDate)
            ])
          }
        }
      ],

      displaceMediaList: [],
      displaceMediaColumns: [
        { title: '资源编号', key: 'resourceCode' },
        { title: '资源类型', key: 'resourceTypeName' },
        { title: '所属站点', key: 'stationName' },
        {
          title: '投放档期',
          key: 'schedule',
          render: (h, data) => {
            return h('div', [
              h('span', data.row.startDate),
              h(
                'span',
                ' 至 '
              ),
              h('span', data.row.endDate)
            ])
          }
        },
        {
          title: '媒体刊例',
          key: 'usePrice',
          render: (h, data) => {
            return h('div', [
              h('span', { class: 'money' }, this.formatMoney(data.row.usePrice))
            ])
          }
        }
      ],

      displaceServiceList: [],
      displaceServiceColumns: [
        { title: '服务费名称', key: 'serviceName' },
        {
          title: '刊例数量',
          key: 'useQuantity',
          render: (h, data) => {
            return h('div', [
              h('span', data.row.useQuantity + data.row.quantityName)
            ])
          }
        },
        {
          title: '刊例总价',
          key: 'useTotalPrice',
          render: (h, data) => {
            return h('div', [
              h('span', { class: 'money' }, this.formatMoney(data.row.useTotalPrice))
            ])
          }
        },
        {
          title: '采购数量',
          key: 'quantity',
          render: (h, data) => {
            return h('div', [
              h('span', data.row.quantity + data.row.quantityName)
            ])
          }
        },
        {
          title: '采购总价',
          key: 'totalPrice',
          render: (h, data) => {
            return h('div', [
              h('span', { class: 'money' }, this.formatMoney(data.row.totalPrice))
            ])
          }
        }
      ],

      displaceMediaTypeList: [],
      displaceMediaTypeColumns: [
        { title: '媒体类型', key: 'goodName' },
        { title: '采购数量', key: 'number' },
        {
          title: '媒体刊例',
          key: 'usePrice',
          render: (h, data) => {
            return h('div', [
              h('span', { class: 'money' }, this.formatMoney(data.row.usePrice))
            ])
          }
        },
        {
          title: '执行价',
          key: 'discountPrice',
          render: (h, data) => {
            return h('div', [
              h(
                'span', { class: 'money' },
                this.formatMoney(data.row.discountPrice)
              ),
              h(
                'span',
                { class: 'discount' },
                '（' + data.row.discount + '折）'
              )
            ])
          }
        }
      ],

      uploadUrl: process.env.VUE_APP_API_URL_V2 + '/ooh-displace/v1/contract/uploadContractFile',
      uploadHeaders: {
        'ooh-auth': getStorage('ooh-token')
      },
      uploadParams: null,
      fileColumns: [
        { title: '文件名称', key: 'fileName' },
        { title: '文件大小 (kb)', key: 'length' },
        { title: '上传时间', key: 'updateTime' },
        {
          title: '操作',
          width: 150,
          align: 'center',
          render: (h, data) => {
            return h('div', [
              h('a', {
                on: {
                  click: () => {
                    this.handleDownload(data.row)
                  }
                }
              }, '下载'),
              this.allowEdit ? h('a', {
                style: {
                  color: '#ef4f4f',
                  'margin-left': '10px'
                },
                on: {
                  click: () => {
                    this.handleRemoveFile(data.row)
                  }
                }
              }, '删除') : ''
            ])
          }
        }
      ],
      fileData: []
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      const queryModel = {
        contractId: this.id
      }

      // 获取合同基本信息
      getContractById(queryModel).then(res => {
        res.formatDepositAmount = this.formatMoney(res.depositAmount)
        this.contractBasicInfo = res
        if (res.type === 2 && this.allowEdit) {
          this.commodityColumns.push({
            title: '操作',
            width: 150,
            align: 'center',
            render: (h, data) => {
              return h('div', [
                h('a', {
                  on: {
                    click: () => {
                      this.$emit('editCommodity', data.row)
                    }
                  }
                }, '编辑'),
                this.allowEdit ? h('a', {
                  style: {
                    color: '#ef4f4f',
                    'margin-left': '10px'
                  },
                  on: {
                    click: () => {
                      this.handleRemoveProduct(data.row)
                    }
                  }
                }, '删除') : ''
              ])
            }
          })
        }
      })

      // 获取合同置换回来的商品
      this.loadProducts()

      // 获取合同售卖的媒体
      listOrderDetail(queryModel).then(res => {
        this.displaceMediaList = res
      })

      // 获取采购的服务费信息
      listService(queryModel).then(res => {
        this.displaceServiceList = res
      })

      // 获取合同设置的媒体折扣
      listDiscount(queryModel).then(res => {
        this.displaceMediaTypeList = res
      })

      // 获取上传附件清单
      this.uploadParams = { contractId: this.id }
      this.loadUpdateFiles()
    },
    formatMoney (number) {
      return toMoney(number)
    },
    loadUpdateFiles () {
      listContractFile({ contractId: this.id }).then(res => {
        this.fileData = res
      })
    },
    loadProducts () {
      listContractDetail({ contractId: this.id }).then(res => {
        this.commodityList = res
      })
    },
    handleDownload (file) {
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-displace/v1/contract/downloadFile', { fileId: file.id }, file.fileName)
    },
    handleRemoveFile (file) {
      this.$Modal.confirm({
        title: '操作提醒',
        content: '您将删除该附件，点击确认后将删除',
        onOk: () => {
          deleteContractFile({ fileId: file.id }).then(res => {
            this.$Notice.success({ desc: '操作成功' })
            this.loadUpdateFiles()
          })
        }
      })
    },
    handleAddCommodity () {
      this.$emit('addCommodity')
    },
    handleRemoveProduct (product) {
      this.$Modal.confirm({
        title: '操作提醒',
        content: '您将删除商品' + product.name + '，点击确认后将删除',
        onOk: () => {
          deleteProduct({ detailId: product.id }).then(res => {
            this.$Notice.success({ desc: '操作成功' })
            this.loadProducts()
          })
        }
      })
    }
  },
  watch: {
    id () {
      this.initPageData()
    }
  }
}
</script>
