<template>
    <div>
      <Card>
        <p slot="title">概况</p>
        <Row>
          <i-col span="8">
           <label class="title m-r-5">客户</label>{{change.advertiserName}}
          </i-col>
            <i-col span="8">
           <label class="title m-r-5">编码</label>{{change.code}}
          </i-col>
           <i-col span="8">
           <label class="title m-r-5">变更创建时间</label>{{change.createTime}}
          </i-col>
          <i-col span="8">
           <label class="title m-r-5"> 变更状态</label><Tag color="green">{{change.statusName}}</Tag>
          </i-col>
          <i-col span="24">
            <Tabs
    >
      <TabPane label="变更明细" name="record">
        <Card>
        <change-detail :detail="changeDetail"></change-detail>
      </Card>
      </TabPane>
      <TabPane label="合同明细" name="invoice">
        <Card :bordered="false" dis-hover v-if="change.contractId">
        <contract-detail :id="change.contractId" :versionId="id" @on-lowerSettlementPrice="onlowerSettlementPrice"></contract-detail>
      </Card>
      </TabPane>
    </Tabs>

          </i-col>
        </Row>
      </Card>
      <!-- <Card>
        <p slot="title">变更明细</p>
        <change-detail :detail="changeDetail"></change-detail>
      </Card>
      <Card :bordered="false" dis-hover v-if="change.contractId">
        <contract-detail :id="change.contractId" @on-lowerSettlementPrice="onlowerSettlementPrice"></contract-detail>
      </Card> -->
    </div>
</template>

<script>

import changeDetail from '@/components/contractChange/ChangeDetail'
// import history from './History'
import { getVersionDetail } from '@/api/scp/contractchange'
import { getChangeFields } from '@/utils/contractChange'
import ContractDetail from '@/components/contract/contractDetail/Contract'

export default {
  props: {
    id: [Number, String]
  },
  data () {
    return {
      change: { },
      changeDetail: []
    }
  },
  methods: {
    getDetail () {
      getVersionDetail({ versionId: this.id }).then(res => {
        this.change = res
        this.changeDetail = getChangeFields(res.changeDetail)

        // this.changeDetail = getChangeFields(res.changeDetail)
      })
    },
    onlowerSettlementPrice (val) {
      this.$emit('on-lowerSettlementPrice', val)
    }
  },
  created () {
    this.getDetail()
  },
  components: {
    // history,
    changeDetail,
    ContractDetail
  }

}
</script>
