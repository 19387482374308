<template>
  <i-table stripe  :columns="columns" :data="products"></i-table>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'

export default {
  props: {
    products: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      columns: []
    }
  },
  created () {
    if (this.products[0].productKind === 3) {
      this.columns = [
        { title: '产品名称', key: 'productName' },
        {
          title: '发布总量',
          render: (h, data) => {
            return h('span', data.row.quantity + data.row.productUnit)
          }
        },
        {
          title: '订单价',
          render: (h, data) => {
            return h('span', { attrs: { class: 'money' } }, toMoney(data.row.totalUsePrice))
          }
        },
        {
          title: '结算价',
          render: (h, data) => {
            const discount = data.row.totalUsePrice === 0 ? 0 : data.row.totalSettlementPrice / data.row.totalUsePrice
            return h('div', [
              h('span', { attrs: { class: 'money' } }, toMoney(data.row.totalSettlementPrice)),
              h('span', { attrs: { class: 'discount' } }, '(' + (discount * 10).toFixed(2) + '折 )')

            ])
          }
        }

      ]
    } else {
      this.columns = [
        { title: '产品名称', key: 'productName' },
        {
          title: '采购数量',
          render: (h, data) => {
            return h('span', data.row.quantity + data.row.productUnit)
          }
        },
        {
          title: '发布总量',
          render: (h, data) => {
            return h('span', data.row.publishLength + data.row.priceUnit)
          }
        },
        {
          title: '订单价',
          render: (h, data) => {
            return h('div', [
              h('span', { class: 'money' }, toMoney(data.row.totalUsePrice)),
              h(
                'span',
                '(' + data.row.usePriceDiscount + ')'
              )
            ])
          }
        },
        {
          title: '结算价',
          render: (h, data) => {
            const discount = data.row.totalUsePrice === 0 ? 0 : data.row.totalSettlementPrice / data.row.totalUsePrice
            return h('div', [
              h('span', { attrs: { class: 'money' } }, toMoney(data.row.totalSettlementPrice)),
              h('span', { attrs: { class: 'discount' } }, '(' + (discount * 10).toFixed(2) + '折 )')

            ])
          }
        }

      ]
    }
  }

}
</script>
