<template>
  <div>
    <h2 class="text-center p-b-5">
      {{ taskTitle }} <Tag>{{fileTypeName}}</Tag> <Tag v-if="isWarning" color="primary">待补充</Tag>
    </h2>
    <Row class="p-b-10" v-if="uploadDesc">
      <i-col span="24">上传备注：{{ uploadDesc }}</i-col>
    </Row>
    <Collapse simple v-model="defaultValue">
      <Panel name="2">
        <b>素材列表</b>
        <Card slot="content">
          <div style="margin-top: 40px">
            <template v-if="approvalImage.length">
              <Divider dashed>画面</Divider>
              <Row :gutter="8">
                <div v-viewer class="images clearfix">
                  <i-col
                    :xs="24"
                    :sm="12"
                    :md="8"
                    :lg="6"
                    v-for="(image, imgIndex) in approvalImage"
                    :key="imgIndex"
                  >
                    <img
                      :src="image.fileKey"
                      :alt="image.fileName"
                      style="cursor: pointer"
                      class="image"
                      :key="image.fileKey"
                      width="200"
                    />
                    <p style="width:200px">{{image.fileName}}</p>
                  </i-col>
                </div>
              </Row>
            </template>
            <template v-if="approvalVideo.length">
              <Divider dashed>视频</Divider>
              <Row :gutter="8">
                <i-col
                  :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="6"
                  v-for="(video, videoIndex) in approvalVideo"
                  :key="videoIndex"
                >
                  <card
                    class="text-center"
                    style="word-break: break-all; word-wrap: break-word"
                  >
                    <a @click="handleVideoPreview(video.fileKey)">{{
                      video.fileName
                    }}</a>
                    <span class="text-green">({{ video.duration }}秒)</span>
                  </card>
                </i-col>
              </Row>
            </template>
          </div>
        </Card>
      </Panel>
      <Panel name="3" v-if="approvalFile.length">
        <b>资质文件列表</b>
        <template slot="content">
          <i-table
           stripe
            :columns="approvalFileColumn"
            :data="approvalFile"
            size="small"
          ></i-table>
        </template>
      </Panel>
    </Collapse>

    <Modal title="视频预览" v-model="visibleVideo" :footer-hide="true">
      <video :src="VideoUrl" controls="controls" style="width: 100%">
        您的浏览器不支持预览视频。
      </video>
    </Modal>
    <!-- 上刊材料预览（画面、视频） -->
  </div>
</template>

<script>
import { agreefile } from '@/api/msp/file'
import { downloadFileRequest } from '@/utils/download'

export default {
  props: {
    id: [Number, String],
    task: {
      type: Object
    }
  },
  data () {
    return {
      defaultValue: '2',
      approvalImage: [],
      approvalVideo: [],
      approvalFile: [],
      approvalFileColumn: [
        { title: '文件名称', key: 'fileName' },
        { title: '文件类型', key: 'mimeType' },
        {
          title: '下载',
          render: (h, params) => {
            return h(
              'a',
              {
                style: { fontSize: '20px' },
                on: {
                  click: () => {
                    this.downloadFile(params.row)
                  }
                }
              },
              [h('Icon', { props: { type: 'md-cloud-download' } })]
            )
          }
        }
      ],
      visibleImage: false,
      imgIndex: 0,
      visibleVideo: false,
      VideoUrl: '',
      downloadFileName: '',
      uploadDesc: '',
      fileTypeName: '',
      contract: {} // 合同信息
    }
  },
  methods: {
    downloadFile (file) {
      downloadFileRequest(
        process.env.VUE_APP_API_URL_V2 + '/ooh-msp/v2/file/downloadfile',
        { fileId: file.id },
        file.fileName
      )
    },
    handleVideoPreview (fileUrl) {
      this.VideoUrl = fileUrl
      this.visibleVideo = true
    },
    formatImageUrl (fileUrl, width, height) {
      const widthstr = width === 0 ? '' : ',w_' + width
      const heightstr = height === 0 ? '' : ',h_' + height

      return (
        fileUrl + '?x-oss-process=image/resize,m_lfit' + widthstr + heightstr
      )
    },
    getApprovalDetails () {
      const _this = this
      _this.approvalImage = []
      _this.approvalFile = []
      _this.approvalVideo = []
      agreefile({ approvalId: _this.id, pageSize: 10000, pageNumber: 1 }).then(
        (res) => {
          _this.uploadDesc = (res.list.length > 0) ? res.list[0].desc : ''
          _this.fileTypeName = (res.list.length > 0) ? res.list[0].fileTypeName : ''

          res.list.forEach((file) => {
            if (file.type === 1) {
              _this.approvalImage.push(file)
            } else if (file.type === 2) {
              _this.approvalFile.push(file)
            } else if (file.type === 3) {
              _this.approvalVideo.push(file)
            }
          })
        }
      )
    },
    handleInitedStatement (params) {
      let fileName = '画面审批表('
      fileName += params.advertiserName
      fileName += params.code
      fileName += ').doc'

      this.downloadFileName = fileName
    },
    handleInitedContract (params) {
      this.contract = params

      let fileName = '画面审批表('
      fileName += params.firstParty
      fileName += params.code
      fileName += ').doc'

      this.downloadFileName = fileName
      this.$refs.ContractOrders.initPageData(
        params.contractId,
        params.versionId
      )
    }
  },
  created () {
    this.getApprovalDetails()
  },
  computed: {
    taskTitle () {
      let title = '填充画面审批'

      if (this.task.props.pictureTypeDesc) {
        title = this.task.props.pictureTypeDesc
      }
      return title
    },
    isWarning () {
      return (
        this.task.processtype === 10 && this.task.processSupplementFeature === 1
      )
    }
  }
}
</script>
