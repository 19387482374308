<template>
  <div>
    <!-- <agreement-detail :baseId="id"></agreement-detail> -->
     <agreement-detail :id="id" :versionId="id" ></agreement-detail>
  </div>
</template>

<script>
import AgreementDetail from '@/components/contract/contractDetail/Contract'
export default {
  props: {
    id: [Number, String]
  },
  components: {
    AgreementDetail
  }
}
</script>
