<template>
  <div>
    <Card>
      <p slot="title"> {{ advertiser.name }}</p>
      <p slot="extra" v-if="advertiser.typeName">
        <Tag type="border" color="red">{{advertiser.typeName}}</Tag>
      </p>
      <Row>
        <i-col :xs="24" :sm="24" :md="24" :lg="24">
          <h3>
            <strong>{{ advertiser.orgCode }}</strong>
          </h3>
            <Row>
              <i-col :xs="12" :sm="12" :md="6" :lg="6">
                <label class="title m-r-5">所属行业</label>{{advertiser.bigTypeName}}
              </i-col>
              <i-col :xs="12" :sm="12" :md="6" :lg="6">
                <label class="title m-r-5">联系电话</label>{{advertiser.tel}}
              </i-col>
              <i-col :xs="12" :sm="12" :md="6" :lg="6">
                <label class="title m-r-5">营业时间</label>{{advertiser.openStartTime}} - {{advertiser.openEndTime}}
              </i-col>
              <i-col :xs="12" :sm="12" :md="6" :lg="6" v-if="advertiser.closeDay">
                <label class="title m-r-5">休息日</label>{{advertiser.closeDay}}
              </i-col>
            </Row>
            <Row>
              <i-col :xs="12" :sm="12" :md="6" :lg="6">
                <label class="title m-r-5">报备人</label>{{advertiser.userName}}
              </i-col>
              <i-col :xs="12" :sm="12" :md="6" :lg="6">
                <label class="title m-r-5">报备时间</label>{{advertiser.createTime}}
              </i-col>
            </Row>
            <Row>
              <i-col :xs="12" :sm="12" :md="6" :lg="6">
                <label class="title m-r-5">地址</label>{{advertiser.address}}
              </i-col>
            </Row>
        </i-col>
      </Row>
    </Card>

    <!-- <customer-searcher :name="advertiser.name" v-if="flag"></customer-searcher> -->
    <div class="m-t-10">
      <Row style="margin:2px 2px 2px 2px">
        <i-col span="8">
          <i-input v-model="customerQuery.keyword"></i-input>

        </i-col>
        <i-col span="4">
          <Button
          type="primary"
          class="m-l-5"
          icon="ios-search"
          @click="getPage"
          >查询</Button
        >
        </i-col>

      </Row>
      <i-table stripe
        :data="list"
        :columns="titles"
      ></i-table>
      <div class="paging_style">
        <Page size="small"
          :total="total"
          :page-size="customerQuery.pageSize"
          :current="customerQuery.pageNumber"
          @on-change="handleChange"
          show-total
          show-elevator
        ></Page>
      </div>
    </div>

  </div>
</template>

<script>
import { getBusiness, allpage } from '@/api/sf/business'
// import { getBrandList } from '@/api/crm/brand'
// import { formatCustomerStatus, formatCustomerType } from '@/utils/tagStatus'
// import CustomerSearcher from '../Component/CustomerSearcher'

export default {
  props: {
    id: [Number, String]
  },
  data () {
    return {
      industrydata: [],
      advertiser: {
        id: null,
        name: '',
        orgCode: '',
        address: '',
        industryId: null,
        type: null,
        tel: '',
        description: '',
        userId: '',
        createTime: '',
        updateTime: ''
      },
      flag: false,
      customerQuery: { keyword: '', pageNumber: 1, pageSize: 10 },
      list: [],
      titles: [
        { title: '名称', align: 'center', key: 'name' },
        { title: '统一社会信用编码', align: 'center', key: 'orgCode' },
        { title: '行业', align: 'center', key: 'bigTypeName' },
        { title: '联系电话', align: 'center', key: 'tel' },
        { title: '地址', align: 'center', key: 'address' },
        { title: '状态', align: 'center', key: 'statusName' }
      ],
      total: 0
    }
  },
  methods: {
    initBusinessInfo () {
      getBusiness({ id: this.id }).then(res => {
        this.flag = true
        this.advertiser = res
        this.customerQuery.keyword = this.advertiser.name
        this.getPage()
      })
    },

    getPage () {
      allpage(this.customerQuery).then(res => {
        this.list = res.list
        this.total = res.totalRow
      })
    },
    handleChange (page) {
      this.customerQuery.pageNumber = page
      this.getPage()
    }
  },
  created () {
    this.initBusinessInfo()
  }
  // components: {
  //   CustomerSearcher
  // }
}
</script>
