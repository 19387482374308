<template>
  <Modal v-model="createAdjustmentItemModel" width="1200" title="新建调整项" >
    <Row :gutter="16">
      <i-col span="12">
        <Timeline>
          <TimelineItem>
            <h4>当前结算信息</h4>

            <i-table
              stripe
              :columns="columnsv2"
              :data="settlementProduct"
            ></i-table>
          </TimelineItem>
        </Timeline>
        <Timeline>
          <TimelineItem>
            <h4>当前采购信息</h4>

            <i-table stripe :columns="columnsv2" :data="products"></i-table>
          </TimelineItem>
        </Timeline>
      </i-col>
      <i-col span="12">
        <Timeline>
          <TimelineItem>
            <h4>调整内容</h4>

            <Form
              ref="adjustmentProductForm"
              :model="adjustmentProduct"
              label-position="top"
            >
              <Row :gutter="10">
                <i-col span="4">
                  <FormItem label="产品">
                    <Select
                      size="small"
                      v-model="adjustmentProduct.productIndex"
                      placeholder="产品"
                    >
                      <Option
                        v-for="(item, index) in adjustmentProducts"
                        :key="index"
                        :value="index"
                        >{{ item.productName }}（{{ item.startDate }}至{{
                          item.endDate
                        }}）</Option
                      >
                    </Select>
                  </FormItem>
                </i-col>
                <i-col span="4">
                  <FormItem label="数量">
                    <i-input
                      size="small"
                      number
                      v-model="adjustmentProduct.quantity"
                      placeholder="调整数额"
                    ></i-input>
                  </FormItem>
                </i-col>
                <i-col span="4">
                  <FormItem label="结算价">
                    <i-input
                      size="small"
                      v-model="adjustmentProduct.settlementprice"
                      prefix="logo-yen"
                      number
                      placeholder="调整数额"
                    ></i-input>
                  </FormItem>
                </i-col>
                <i-col span="4">
                  <FormItem label="制作费">
                    <i-input
                      size="small"
                      v-model="adjustmentProduct.produceprice"
                      prefix="logo-yen"
                      number
                      placeholder="调整数额"
                    ></i-input>
                  </FormItem>
                </i-col>
                <i-col span="4">
                  <FormItem label="上刊费">
                    <i-input
                      size="small"
                      v-model="adjustmentProduct.installprice"
                      prefix="logo-yen"
                      number
                      placeholder="调整数额"
                    ></i-input>
                  </FormItem>
                </i-col>
                <i-col span="4">
                  <FormItem label=" ">
                    <Button
                      type="success"
                      size="small"
                      class="m-l-8"
                      @click="saveChange"
                      >保存</Button
                    >
                  </FormItem>
                </i-col>
              </Row>
            </Form>
            <i-table
              stripe
              :columns="adjustmentItemcolumns"
              :data="adjustments"
            ></i-table>
          </TimelineItem>
        </Timeline>
        <Timeline>
          <TimelineItem>
            <h4>结算日期</h4>
            于
            <DatePicker
              size="small"
              v-model="settlementDate"
              placeholder="选择结算日期"
              style="width: 120px"
            />
            之前结算调整金额<span style="color: #44bd32">
              {{ formatMoney(totalChangePrice) }}</span
            >
          </TimelineItem>
        </Timeline>
        <Timeline>
          <TimelineItem>
            <h4>调整说明</h4>
            <Input
              type="textarea"
              :rows="4"
              v-model="remark"
              placeholder="请输入此次调整项说明信息"
            />
          </TimelineItem>
        </Timeline>
        <Timeline>
          <TimelineItem>
            <h4>附件</h4>

            <Upload
              multiple
              type="drag"
              :before-upload="handleBeforeUploadFile"
              action=""
              :format="['jpg','jpeg','png','pdf','doc','docx','xls','xlsx']"
            >
              <div style="padding: 20px 0">
                <Icon
                  type="ios-cloud-upload"
                  size="52"
                  style="color: #3399ff"
                ></Icon>
                <p>点击或拖拽文件到这里进行上传</p>
              </div>
            </Upload>
            <Row v-show="fileList.length">
              <i-col span="16"><b>文件名称</b></i-col>
              <i-col span="6"><b>文件大小</b></i-col>
              <i-col span="2"><b>操作</b></i-col>
              <hr />
            </Row>
             <Row
        v-for="(file,fileIndex) in fileList"
        :key="fileIndex"
        v-show="fileList.length"
      >
        <i-col span="16">{{file.name}}</i-col>
        <i-col span="6">{{(file.size/1024).toFixed(2)}}KB</i-col>
        <i-col span="2"><a
            class="delete"
            @click="handleRemove(file)"
          >删除</a></i-col>
        <i-col span="24">
          <hr>
        </i-col>
      </Row>
          </TimelineItem>
        </Timeline>
      </i-col>
    </Row>
    <div slot="footer">
      <Button type="text" @click="createAdjustmentItemModel=false">取消</Button>
      <Button type="primary"  :loading="loading"  @click="confirmAdjustment">确定</Button>
    </div>
  </Modal>
</template>

<script>
import axios from 'axios'
import { getStorage } from '@/utils/storage'
import { getorderplanprice, getmergedorderschedulecategorylist } from '@/api/scp/statementorder'

import { GetCurrentSchedule, ParseDateTime } from '@/utils/dateFormat'
import { toMoney } from '@/utils/wnumb_own'

// import { formatStatementStatus } from '@/utils/tagStatus'
export default {
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    versionId: {
      type: [Number, String],
      required: true
    },
    orderId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      loading: false,
      adjustmentItemcolumns: [
        {
          title: '调整产品',
          render: (h, data) => {
            return h(
              'span',
              data.row.productName +
                '（' +
                data.row.startDate +
                '至' +
                data.row.endDate +
                '）'
            )
          }
        },
        {
          title: '数量',
          render: (h, data) => {
            return h('span', data.row.normalQuantity)
          }
        },
        {
          title: '结算价',
          render: (h, data) => {
            return h('span', data.row.normalSettlementPrice)
          }
        },
        {
          title: '制作费',
          render: (h, data) => {
            return h('span', data.row.totalProductPrice)
          }
        },
        {
          title: '上刊费',
          render: (h, data) => {
            return h('span', data.row.totalInstallPrice)
          }
        },
        {
          title: '操作',
          render: (h, data) => {
            // const editButton = h(
            //   'a',
            //   {
            //     style: { 'margin-left': '5px' },
            //     on: {
            //       click: () => {
            //         this.adjustmentProduct.productIndex = data.row.productIndex
            //         this.adjustmentProduct.quantity = data.row.quantity
            //         this.adjustmentProduct.settlementprice = data.row.settlementprice
            //         this.adjustmentProduct.produceprice = data.row.produceprice
            //         this.adjustmentProduct.installprice = data.row.installprice
            //       }
            //     }
            //   },
            //   '编辑'
            // )
            const deleteButton = h(
              'a',
              {
                style: { 'margin-left': '5px', color: '#ef4f4f' },
                on: {
                  click: () => {
                    this.$Modal.confirm({
                      title: '操作确认',
                      content: '是否需要删除当前调整项？',
                      onOk: () => {
                        this.adjustments.splice(data.index, 1)
                        this.calcTotalPrice()
                      }
                    })
                  }
                }
              },
              '删除'
            )
            const returnBtn = [deleteButton]
            return h('div', returnBtn)
          }
        }
      ],

      createAdjustmentItemModel: false,
      adjustmentProduct: {
        productIndex: null,
        quantity: null,
        settlementprice: null,
        installprice: null,
        produceprice: null
      },

      products: [], // 订单当前采购信息
      settlementProduct: [], // 当前结算信息

      adjustmentProducts: [],

      columnsv2: [
        {
          title: '产品名称',
          render: (h, data) => {
            return h('span', data.row.productName)
          }
        },
        {
          title: '档期',
          render: (h, data) => {
            return h(
              'span',
              this.computeSchedule(data.row.startDate, data.row.endDate)
            )
          }
        },
        {
          title: '采购数量',
          render: (h, data) => {
            return h('span', data.row.normalQuantity + data.row.productUnit)
          }
        },
        {
          title: '订单价',
          render: (h, data) => {
            return h('span', toMoney(data.row.normalUsePrice))
          }
        },
        {
          title: '结算价',
          render: (h, data) => {
            return h('span', toMoney(data.row.normalSettlementPrice))
          }
        },
        {
          title: '制作费',
          render: (h, data) => {
            return h('span', toMoney(data.row.totalProducePrice))
          }
        },
        {
          title: '上刊费',
          render: (h, data) => {
            return h('span', toMoney(data.row.totalInstallPrice))
          }
        }
      ],
      adjustments: [],
      fileList: [],
      remark: '',
      settlementDate: '',
      totalChangePrice: 0
    }
  },
  created () {},
  watch: {
    settlementProduct () {}
  },
  methods: {
    initData () {
      this.adjustmentProducts = []
      // 获取当前结算信息
      getmergedorderschedulecategorylist({
        bid: this.id,
        versionId: this.versionId
      }).then((res) => {
        this.settlementProduct = res
        // 获取当前采购信息
        getorderplanprice({ orderId: this.orderId }).then((products) => {
          const now = new Date()
          this.orderSnapshotTime = ParseDateTime(now)

          this.products = products.map((item) => {
            item.orderId = this.orderId
            return item
          })
          res.forEach((item) => {
            if (
              !this.adjustmentProducts.filter(
                (x) =>
                  x.orderId === item.orderId &&
                  x.goodId === item.goodId &&
                  x.productId === item.productId &&
                  x.startDate === item.startDate &&
                  x.endDate === item.endDate
              ).length
            ) {
              this.adjustmentProducts.push({
                orderId: item.orderId,
                goodId: item.goodId,
                productId: item.productId,
                startDate: item.startDate,
                endDate: item.endDate,
                productName: item.productName
              })
            }
          })
          products.forEach((item) => {
            if (
              !this.adjustmentProducts.filter(
                (x) =>
                  x.orderId === item.orderId &&
                  x.goodId === item.goodId &&
                  x.productId === item.productId &&
                  x.startDate === item.startDate &&
                  x.endDate === item.endDate
              ).length
            ) {
              this.adjustmentProducts.push({
                orderId: this.orderId,
                goodId: item.goodId,
                productId: item.productId,
                startDate: item.startDate,
                endDate: item.endDate,
                productName: item.productName
              })
            }
          })
        })
      })

      this.resetForm()
      this.adjustments = []

      this.createAdjustmentItemModel = true
    },
    resetForm () {
      this.adjustmentProduct.productIndex = null
      this.adjustmentProduct.quantity = null
      this.adjustmentProduct.settlementprice = null
      this.adjustmentProduct.produceprice = null
      this.adjustmentProduct.installprice = null
    },
    // 保存调整项
    saveChange () {
      const that = this
      if (this.adjustmentProduct.productIndex !== null) {
        const adjustmentProduct =
          this.adjustmentProducts[this.adjustmentProduct.productIndex]
        that.adjustments.push({
          productIndex: this.adjustmentProduct.productIndex,
          orderId: adjustmentProduct.orderId,
          goodId: adjustmentProduct.goodId,
          productId: adjustmentProduct.productId,
          productName: adjustmentProduct.productName,
          startDate: adjustmentProduct.startDate,
          endDate: adjustmentProduct.endDate,

          normalQuantity: parseInt(that.adjustmentProduct.quantity) || 0,
          normalSettlementPrice: parseFloat(that.adjustmentProduct.settlementprice) || 0,
          totalProductPrice: parseFloat(that.adjustmentProduct.produceprice) || 0,
          totalInstallPrice: parseFloat(that.adjustmentProduct.installprice) || 0
        })
      }
      that.resetForm()
      this.calcTotalPrice()
    },
    // 计算总金额
    calcTotalPrice () {
      const that = this
      let totalprice = 0
      that.adjustments.forEach(item => {
        totalprice = totalprice + item.normalSettlementPrice + item.totalProductPrice + item.totalInstallPrice
      })
      this.totalChangePrice = totalprice
    },

    // 文件添加和移除
    handleBeforeUploadFile (file) {
      this.fileList.push(file)
      return false
    },
    handleRemove (file) {
      this.fileList.splice(this.fileList.indexOf(file), 1)
    },
    formatMoney (number) {
      return toMoney(number)
    },
    computeSchedule (startDate, endDate) {
      return GetCurrentSchedule(startDate, endDate)
    },
    // 提交调整项
    confirmAdjustment () {
      const that = this
      if (!that.settlementDate || !that.adjustments.length) {
        that.$Notice.error({ desc: '请先完善您的调整项信息！' })
        return
      }
      that.loading = true
      // 开始批量执行上传操作
      const uploadData = new FormData()
      uploadData.append('adjustId', -1)
      uploadData.append('mark', that.remark)
      uploadData.append('bid', that.id)
      uploadData.append('versionId', that.versionId)
      uploadData.append('orderSnapshotTime', that.orderSnapshotTime)
      uploadData.append(
        'paymentListStr',
        JSON.stringify([{ amount: that.totalChangePrice, date: that.settlementDate }])
      )
      uploadData.append(
        'scpAdjustItemListStr',
        JSON.stringify(that.adjustments)
      )

      that.fileList.forEach(function (file, index) {
        uploadData.append('files', file)
      })

      axios
        .post(
          process.env.VUE_APP_API_URL_V2 +
                '/ooh-scp/v1/scpbusinessadjust/createorupdateadjust',
          uploadData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'ooh-auth': getStorage('ooh-token')
            },
            withCredentials: true
          }
        )
        .then((res) => {
          that.loading = false
          if (res && res.data && !res.data.errcode) {
            that.$Notice.success({ desc: '结算单调整项创建成功！' })
            this.$emit('finishConfirm')
            that.createAdjustmentItemModel = false
          } else {
            that.$Notice.error({ desc: res.data.errmsg })
          }
        })
    }
  }
}
</script>
