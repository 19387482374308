<style scoped>
.images {
  cursor: pointer;
  display: flex;
}

.images img {
  max-width: 300px;
  width: 100%;
  cursor: pointer;
}
</style>
<template>
  <div>
    <h2 class="text-center p-b-5">
      {{ taskTitle }} <Tag v-if="isWarning" color="primary">待补充</Tag>
    </h2>
    <!-- <Alert type="warning" closable>
      <ad-approval-quali :orderId="orderId" />
    </Alert> -->
    <Row class="p-b-10 m-t-10">
      <i-col span="24"><label class="title m-r-5">客户</label>{{ task.props.advertiserName }}</i-col>
      <i-col v-if="contract.public" span="24"><label class="title m-r-5">备注</label>{{ contract.contractName }}</i-col>
      <i-col v-else span="24"><label class="title m-r-5">品牌</label>{{ task.props.brandName }}</i-col>
    </Row>
    <Collapse simple v-model="defaultValue">
      <Panel name="1" v-if="task.props.contractId && isAuth('ApprovalTask_ShowContract')">
        <template v-if="task.props.contractType === 2">
          <b>结算单详情</b>
          <statementDetailComponent slot="content" :id="task.props.contractId" :versionId="task.props.contractId" :loadType="false"
            @on-inited="handleInitedStatement"></statementDetailComponent>
        </template>
        <template v-else>
          <b v-if="task.props.contractType === 4">确认单详情</b>
          <b v-else>合同详情</b>
          <contractComponent slot="content" :theme="'black'" :id="task.props.contractId"  :versionId="task.props.contractId"
            @on-inited="handleInitedContract"></contractComponent>
        </template>
      </Panel>
      <Panel name="1" v-else-if="task.props.orderId">
        <b>采购详情</b>
        <orderDetailComponent slot="content" :orderId="task.props.orderId"/>
      </Panel>

      <Panel name="2">
        <b>素材列表</b>
        <Card slot="content">
          <i-button slot="extra" v-if="task.status === 1" type="warning" @click="handleDownload">下载画面审批模板</i-button>
          <div class="p-t-10">
            <p v-if="uploadDesc !== ''">
              <strong>上传备注：</strong>{{ uploadDesc }}
            </p>

            <template v-if="approvalImage.length">
              <Divider dashed>画面</Divider>
              <div v-viewer class="images clearfix">
                <Row :gutter="8" style="width: 100%;">
                  <i-col :xs="24" :sm="12" :md="8" :lg="6" v-for="(image, imgIndex) in approvalImage" :key="imgIndex">
                    <img :src="image.fileKey" :alt="image.fileName" class="image" :key="image.fileKey" />
                    <p style="width:200px">{{ image.fileName }}</p>
                  </i-col>
                </Row>
              </div>
            </template>
            <template v-if="approvalVideo.length">
              <Divider dashed>视频</Divider>
              <Row :gutter="8">
                <i-col :xs="24" :sm="12" :md="8" :lg="6" v-for="(video, videoIndex) in approvalVideo" :key="videoIndex">
                  <card class="text-center" style="word-break: break-all;word-wrap: break-word;">
                    <a @click="handleVideoPreview(video.fileKey)">{{ video.fileName }}</a> <span class="text-green">({{
        video.duration }}秒)</span>
                  </card>
                </i-col>
              </Row>
            </template>
            <template v-if="approvalVoice.length">
              <Divider dashed>音频</Divider>
              <Row :gutter="8">
                <i-col :xs="24" :sm="12" :md="8" :lg="6" v-for="(voice, voiceIndex) in approvalVoice" :key="voiceIndex">
                  <card class="text-center" style="word-break: break-all;word-wrap: break-word;">
                    <a @click="handleVideoPreview(voice.fileKey)">{{ voice.fileName }}</a> <span class="text-green">({{
        voice.duration }}秒)</span>
                  </card>
                </i-col>
              </Row>
            </template>
          </div>
        </Card>
      </Panel>
      <Panel name="3" v-if="approvalFile.length">
        <b>资质文件列表</b>
        <template slot="content">
          <i-table stripe :columns="approvalFileColumn" :data="approvalFile" size="small"></i-table>
        </template>
      </Panel>
    </Collapse>

    <!-- 上刊材料预览（画面、视频） -->
    <Modal title="画面预览" v-model="visibleImage" width="1000" :footer-hide="true">
      <img-light-box-component ref="imgLightBox" :imgIndex.sync="imgIndex"></img-light-box-component>
    </Modal>

    <Modal title="音视频预览" v-model="visibleVideo" :footer-hide="true">
      <video :src="VideoUrl" controls="controls" style="width:100%">
        您的浏览器不支持预览视频。
      </video>
    </Modal>
    <!-- 上刊材料预览（画面、视频） -->
  </div>

</template>

<script>
import { fileByApproval, getApproval } from '@/api/order/orderfile'
import { downloadFileRequest } from '@/utils/download'

import ImgLightBoxComponent from '@/components/common/ImgLightBox'
import statementDetailComponent from '@/components/statement/StatementDetailComponent'
import contractComponent from '@/components/contract/contractDetail/Contract'
// import AdApprovalQuali from '@/components/common/AdApprovalQuali'
import orderDetailComponent from './OrderDetail.vue'

export default {
  props: {
    id: [Number, String],
    task: {
      type: Object
    }
  },
  components: {
    statementDetailComponent,
    contractComponent,
    orderDetailComponent,
    // ContractOrdersComponent,
    ImgLightBoxComponent
    // AdApprovalQuali
  },
  data () {
    return {
      defaultValue: '2',
      approvalImage: [],
      approvalVideo: [],
      approvalVoice: [],
      approvalFile: [],
      approvalFileColumn: [
        { title: '文件名称', key: 'fileName' },
        { title: '文件类型', key: 'mimeType' },
        {
          title: '下载',
          render: (h, params) => {
            return h('a',
              {
                style: { fontSize: '20px' },
                on: {
                  click: () => {
                    this.downloadFile(params.row)
                  }
                }

              },
              [h('Icon', { props: { type: 'md-cloud-download' } })]
            )
          }
        }
      ],
      visibleImage: false,
      imgIndex: 0,
      visibleVideo: false,
      VideoUrl: '',
      downloadFileName: '',
      uploadDesc: '',
      orderId: 0,
      contract: {} // 合同信息
    }
  },
  methods: {
    downloadFile (file) {
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-order/v1/orderfile/downloadfile', { fileId: file.id }, file.fileName)
    },
    handleDownload () { // 下载画面审批模板
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-order/v1/orderfile/installpicture/download', { approvalId: this.id }, this.downloadFileName)
    },
    // handlePreview (imgIndex) {
    //   // let viewer = new Viewer(document.getElementById('a_img_' + imgIndex + ''), {
    //   //   url: this.approvalImage[imgIndex].fileKey
    //   // })
    //   // viewer.show()
    //   const postData = {
    //     startIndex: imgIndex,
    //     imgList: this.approvalImage.map(x => x.fileKey)
    //   }
    //   this.$refs.imgLightBox.init(postData)
    //   this.visibleImage = true
    // },
    handleVideoPreview (fileUrl) {
      this.VideoUrl = fileUrl
      this.visibleVideo = true
    },
    formatImageUrl (fileUrl, width, height) {
      const widthstr = (width === 0) ? '' : ',w_' + width
      const heightstr = (height === 0) ? '' : ',h_' + height

      return fileUrl + '?x-oss-process=image/resize,m_lfit' + widthstr + heightstr
    },
    getApprovalDetails () {
      const _this = this
      _this.approvalScreen = []
      _this.approvalFile = []
      const data = {
        approvalId: _this.id
      }
      fileByApproval(data).then(res => {
        res.forEach(file => {
          if (file.type === 1) {
            _this.approvalImage.push(file)
          } else if (file.type === 2) {
            _this.approvalFile.push(file)
          } else if (file.type === 3) {
            _this.approvalVideo.push(file)
          } else if (file.type === 4) {
            _this.approvalVoice.push(file)
          }
        })
      })
      getApproval(data).then(res => {
        this.uploadDesc = res.desc
        this.orderId = res.orderId
      })
    },
    handleInitedStatement (params) {
      let fileName = '画面审批表('
      fileName += params.advertiserName
      fileName += params.code
      fileName += ').doc'

      this.downloadFileName = fileName
    },
    handleInitedContract (params) {
      this.contract = params

      let fileName = '画面审批表('
      fileName += params.firstParty
      fileName += params.code
      fileName += ').doc'

      this.downloadFileName = fileName
      this.$refs.ContractOrders.initPageData(params.contractId, params.versionId)
    }
  },
  created () {
    this.getApprovalDetails()
  },
  computed: {
    taskTitle () {
      let title = '画面审批'

      if (this.task.props.pictureTypeDesc) {
        title = this.task.props.pictureTypeDesc
      }
      return title
    },
    isWarning () {
      return this.task.processtype === 10 && this.task.processSupplementFeature === 1
    }
  }
}
</script>
