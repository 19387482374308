<style>
.serviceRemark_detail {
  min-width: 200px;
  /* height: 50px; */
  padding: 5px;
  border: 1px solid #5cadff;
  border-radius: 3px;
  color: #808695;
  font-weight: 400;

  position: fixed;
  top: 325px;
  background-color: papayawhip;
  z-index: 999;
}
</style>
<template>
  <div>
    <Modal
      v-model="show"
      :title="'订单' + '(' + orderDetail.orderNo + ')'"
      width="800"
      :footer-hide="true"
    >
      <!-- <p slot="header" class="text-center">设备详情</p> -->
      <div>
        <Row class="p-b-10">
          <i-col span="8">
            <span class="title">订单类型</span>{{ orderDetail.orderTypeName }}
          </i-col>
          <i-col span="8">
            <span class="title">支付方式</span>{{ orderDetail.payTypeName }}
          </i-col>
          <i-col span="8">
            <span class="title">支付时间</span>{{ orderDetail.payTime }}
          </i-col>
        </Row>
        <Row class="p-b-10">
          <i-col span="8">
            <span class="title">下单人</span>{{ orderDetail.buyerName }}
          </i-col>

          <i-col span="8">
            <span class="title">联系电话</span>{{ orderDetail.buyerPhone }}
          </i-col>
          <i-col span="8">
            <span class="title">订单状态</span>{{ orderDetail.statusName }}
          </i-col>
        </Row>
        <Row class="p-b-10">
          <i-col span="24">
            <span class="title">买家留言</span
            >{{ orderDetail.buyerMessage || "-" }}
          </i-col>
        </Row>
        <Row class="p-b-10">
          <i-col span="24">
            <span class="title">配送方式</span
            >{{
              orderDetail.expressTypeName
            }}
          </i-col>
        </Row>
        <Row class="p-b-10">
          <i-col span="24">
            <span class="title">收件人地址</span
            >{{
              orderDetail.deliveryProvince +
              orderDetail.deliveryCity +
              orderDetail.deliveryDistrict +
              orderDetail.buyerDetailAddress
            }}
          </i-col>
        </Row>
      </div>
      <div>
        <h4 class="workplatform-title m-t-10 m-b-10">商品明细</h4>
        <Table
          size="small"
          stripe
          :data="orderDetail.orderItemVOList"
          :columns="orderItemColumn"
        ></Table>
      </div>
    </Modal>

    <Modal v-model="imgShowModal" width="1000" footer-hide>
      <p slot="header" style="text-align: center">
        {{ currentTitle }}
      </p>
      <div class="text-center">
        <img
          class="bigImg"
          style="width: 960px"
          :src="imageUrl + '?x-oss-process=image/resize,w_760'"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { getOrderDetail } from '@/api/mediamall/order'
export default {
  components: {},
  props: {
    id: {
      type: Number
    }
  },
  computed: {},
  data () {
    return {
      show: false,

      orderDetail: {},

      orderItemColumn: [
        { title: '商品名称', align: 'center', key: 'title' },
        {
          title: '数量',
          align: 'center',
          key: 'num'
        },
        {
          title: '商品图片',
          minWidth: 100,
          align: 'center',
          key: 'picPath',
          render: (h, params) => {
            const that = this
            if (params.row.picPath) {
              return h('div', [
                h('img', {
                  domProps: {
                    src:
                      params.row.picPath + '?x-oss-process=image/resize,w_64'
                  },
                  on: {
                    click: () => {
                      that.currentTitle = params.row.title
                      that.imageUrl = params.row.picPath
                      that.imgShowModal = true
                    }
                  },
                  style: {
                    width: '64px',
                    cursor: 'pointer'
                  }
                })
              ])
            } else {
              return h('span', '暂无素材')
            }
          }
        },
        {
          title: '商品金额',
          align: 'center',
          key: 'totalFee',
          render: (h, data) => {
            return h(
              'span',
              {
                style: {
                  color: '#19be6b'
                }
              },
              toMoney(data.row.totalFee)
            )
          }
        },
        {
          title: '实付金额',
          align: 'center',
          key: 'totalAmount',
          render: (h, data) => {
            return h(
              'span',
              {
                style: {
                  color: '#19be6b'
                }
              },
              toMoney(data.row.totalAmount)
            )
          }
        },
        {
          title: '状态',
          key: 'expressStateName'
        }
      ],
      imgShowModal: false,
      currentTitle: '',
      imageUrl: ''
    }
  },
  methods: {
    formatMoney (mon) {
      return toMoney(mon)
    },
    showModal (orderNo) {
      getOrderDetail({ orderNo: orderNo }).then((res) => {
        this.orderDetail = res
      })

      this.show = true
    }
  }
}
</script>
