import request from '@/utils/requestV2'
const qs = require('qs')

// 获取订单的全部审批记录
export function allFileApproval (data) {
  return request({
    url: '/ooh-order/v1/orderfile/allfileapproval',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取订单全部通过的画面
export function agreeFile (data) {
  return request({
    url: '/ooh-order/v1/orderfile/agreefile',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取审批记录对应的画面
export function fileByApproval (data) {
  return request({
    url: '/ooh-order/v1/orderfile/filebyapproval',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取审批记录
export function getApproval (data) {
  return request({
    url: '/ooh-order/v1/orderfile/getapproval',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取审批记录
export function terminateprocess (data) {
  return request({
    url: '/ooh-order/v1/orderfile/terminateprocess',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 根据审批id获取审批内容详情含画面信息
export function getapprovalinfo (data) {
  return request({
    url: '/ooh-order/om/v1/orderfile/getapprovalinfo',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 合并分片上传文件
export function mergeAndCompress (data) {
  return request({
    url: '/ooh-order/v1/orderfile/mergeAndCompress',
    method: 'post',
    data: qs.stringify(data),
    timeout: 600000
  })
}
