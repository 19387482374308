<style scoped>
.lable-title{
    width: 80px;
    display: inline-block;
}
</style>

<template>
    <div>
        <h2 class="text-center">
          通用审批<tag v-if="task && task.processtypeFeature === 1" color="red" type="border">{{task.processtypeFeatureName}}</tag>
        </h2>
        <Card :bordered="false" dis-hover>
            <h4 slot="title">基础信息</h4>
            <p class="p-b-5"><label class="title m-r-5">名称</label>{{besicInfo.name}}  <Tag :color="getStatusColor(besicInfo.status)">{{besicInfo.statusName}}</Tag></p>
            <p class="p-b-5"><label class="title m-r-5">描述</label>{{besicInfo.content}}</p>
            <p class="p-b-5"><label class="title m-r-5">发起人</label>{{besicInfo.userName}}</p>
            <p class="p-b-5"><label class="title m-r-5">提交时间</label>{{besicInfo.createTime}}</p>
            <p class="p-b-5">
              <label class="title m-r-5">备注</label>
              {{besicInfo.desc}}
            </p>
        </Card>

        <Card :bordered="false" dis-hover>
          <h4 slot="title">上传文件清单</h4>
          <i-table  stripe  :columns="approvalFileColumn" :data="approvalFile" size="small"></i-table>
        </Card>
    </div>
</template>

<script>
import { downloadFileRequest } from '@/utils/download'
import { formatApprovalResult } from '@/utils/tagStatus'
import { getApprovalById, fileByApprovalId } from '@/api/sys/approval'

export default {
  props: {
    id: [Number, String],
    task: {
      type: Object
    }
  },
  // inject: ['reload'],
  data () {
    return {
      besicInfo: {},
      approvalFile: [],
      approvalFileColumn: [
        {
          title: '文件缩略图',
          render: (h, data) => {
            if (data.row.mimeType.startsWith('image')) {
              return h('Icon', {
                props: { type: 'md-images', size: 28, color: '#e96900' },
                style: { 'margin-left': '8px' }
              })
            } else if (this.isMSWord(data.row)) {
              return h('img', { attrs: { src: require('@/assets/images/WinWordLogoSmall.scale-180.png'), height: '30px' } })
            } else if (this.isMSExcel(data.row)) {
              return h('img', { attrs: { src: require('@/assets/images/ExcelLogoSmall.scale-180.png'), height: '30px' } })
            }
          }
        },
        { title: '文件名称', key: 'fileName' },
        { title: '文件大小 (kb)', key: 'length' },
        {
          title: '下载',
          render: (h, params) => {
            return h('a',
              {
                style: { fontSize: '20px' },
                on: {
                  click: () => {
                    this.downloadFile(params.row)
                  }
                }
              },
              [h('Icon', { props: { type: 'md-cloud-download' } })]
            )
          }
        }
      ],
      records: [],
      titles: [
        { title: '审批人', key: 'approverUserName' },
        { title: '审批时间', key: 'finishTime' },
        {
          title: '审批意见',
          render: (h, data) => {
            return formatApprovalResult(h, data.row.approvalResult, data.row.approvalResultName)
          }
        },
        { title: '审批备注', key: 'comment' }
      ]
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      const query = { approvalId: this.id }
      getApprovalById(query).then(res => {
        this.besicInfo = res
      })

      fileByApprovalId(query).then(res => {
        this.approvalFile = res
      })
    },
    getStatusColor (result) {
      let color = 'Gray'
      if (result === 4) { // 待审批
        color = 'Orange'
      } else if (result === 5) { // 已通过
        color = 'Black'
      } else if (result === 6) { // 已拒绝
        color = 'Red'
      }

      return color
    },
    downloadFile (file) {
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-sys/v1/approval/downloadfile', { fileId: file.id }, file.fileName)
    },
    isMSWord (file) {
      const mimeTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword']
      return mimeTypes.includes(file.mimeType)
    },
    isMSExcel (file) {
      const mimeTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
      return mimeTypes.includes(file.mimeType)
    }
  }
}
</script>
