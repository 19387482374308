import request from '@/utils/requestV2'
const qs = require('qs')

// 获取调整项列表
export function getadjustlist (data) {
  return request({
    url: '/ooh-scp/v1/scpbusinessadjust/getadjustlist',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取调整项详情
export function getadjust (data) {
  return request({
    url: '/ooh-scp/v1/scpbusinessadjust/getadjust',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 删除调整项
export function deleteadjust (data) {
  return request({
    url: '/ooh-scp/v1/scpbusinessadjust/deleteadjust',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 创建或者更新调整项
export function createorupdateadjust (data) {
  return request({
    url: '/ooh-scp/v1/scpbusinessadjust/createorupdateadjust',
    method: 'post',
    data: qs.stringify(data)
  })
}
