<style scoped>
.titleInfo{
  text-decoration: underline;
  font-weight: bold;
  font-size: 14px;
}
</style>

<template>
    <div>
        <h4 class="text-center workplatform-title">媒体广告发布结算单</h4>
        <p><span  class="title">结算单编码 </span> {{statement.code}}</p>
        <p><span  class="title">发布类型 </span> {{statement.contractCategoryName}}</p>
        <p v-if="statement.salePolicy===2"><span  class="title">发布标记 </span> {{statement.salePolicyName}}</p>
        <p><span  class="title">甲方 </span> {{firstParty}}</p>
        <p><span class="title">乙方 </span> {{secondParty}}</p>
        <br/>
        <p>
          按照双方签订的年度广告发布《合作协议》（以下简称“年度合同”），以下列表为本次乙方代理
          <span class="text-orange">{{order.brandName}}</span> 广告发布信息，发布日期为：
          <span class="text-orange">{{order.schedule}}</span>
          <i-button type="primary" style="display:inline-block;" class="m-l-10" size="small" @click="handleShowOrder(order.id)">查看订单详情</i-button>
        </p>
         <div v-show="normal.length>0">
          <div v-for="category in normal" :key="category.categoryId">
            <Tag color="blue"> {{category.category}} </Tag>
            <Timeline>
              <TimelineItem v-for="(schedule,i) in category.schedules" :key="i">
                <h4>{{computeSchedule(schedule)}} </h4>
                <products :products="schedule"></products>

              </TimelineItem>
            </Timeline>
          </div>

        </div>
        <div v-show="special.length>0">
            <Divider dashed>特惠资源</Divider>
            <div v-for="category in special" :key="category.categoryId">
              <Tag color="blue"> {{category.category}} </Tag>
              <Timeline>
                <TimelineItem v-for="(schedule,i) in category.schedules" :key="i">
                  <h4>{{computeSchedule(schedule)}} </h4>
                  <products :products="schedule"></products>

                </TimelineItem>
              </Timeline>
            </div>

        </div>

        <Card :bordered="false" dis-hover>
          <p slot="title">付款信息</p>
          <p>
            经过核算，乙方的广告投放共产生了费用：
            <span class="money">{{formateMoney(noDiscountTotal)}}, （人民币 {{formateUpperMoney(noDiscountTotal)}} ） </span>,乙方选择刊前付款
            <span class="discount">{{(proportion*100).toFixed(2)}}%</span> ,所以乙方享有媒介使用费折扣
            <span class="discount">{{(discount*10).toFixed(2)}}折</span>，故共需付费
            <span class="money"> {{formateMoney(totalMoney)}}, （人民币 {{formateUpperMoney(totalMoney)}} ） </span>
          </p>
          <p v-show="payments.length>0">乙方应按下列期限及方式将合同款如期支付给甲方：</p>
          <ol>
            <li v-for="(item,i) in payments" :key="12545+i">
              {{item.date}} 前支付
              <span class="money">{{formateMoney(item.amount)}} 元（大写：人民币 {{formateUpperMoney(item.amount)}}）</span>
            </li>
          </ol>
        </Card>
        <Card :bordered="false" dis-hover>
          <p slot="title">附加信息</p>
          <ol>
            <li>本次广告发布服务费（即画面制作费和上下刊费）根据乙方工作人员在交易平台上提交的服务需求累积计算，服务费总金额将在 <span class="text-orange"> 服务费明细</span> 中显示。乙方应在画面上刊前结清服务费，否则甲方有权不予发布广告。</li>
            <li>根据乙方的发布需求，甲方预估乙方在发布的第一个档期产生的服务费为人民币 <span class="money">{{formateMoney(serviceMount)}}（大写：人民币 {{formateUpperMoney(serviceMount)}}）</span>。但最终费用以实际发生的为准。</li>
          </ol>
        </Card>

        <!-- 弹窗 订单详情 -->
        <OrderDetail ref="orderDetail"/>
    </div>
</template>

<script>
import Products from './Products'
import OrderDetail from '@/components/order/common/ModalOrderDetail'

import { toMoney } from '@/utils/wnumb_own'
import { parseUpperMoney } from '@/utils'
import { GetCurrentSchedule } from '@/utils/dateFormat'
import { buildCategories } from '@/utils/orderUtils'

import { getOrder } from '@/api/order/order'
import { getProductPrice } from '@/api/order/orderprice'

export default {
  props: {
    statementBean: {
      type: Object,
      required: true
    }
  },
  components: {
    Products, OrderDetail
  },
  data () {
    return {
      firstParty: '',
      secondParty: '',
      order: {},
      totalMoney: 0,
      invalidmark: '',
      proportion: 0,
      discount: 0,
      orderId: 0,
      statement: {},
      normal: [],
      special: [],
      products: []
    }
  },
  methods: {
    formateMoney (number) {
      return toMoney(number)
    },
    formateUpperMoney (money) {
      return parseUpperMoney(money)
    },
    initPage () {
      this.orderId = this.statementBean.orderIdList[0]
      this.payments = this.statementBean.paymentBeanList
      this.firstParty = this.statementBean.firstParty
      this.secondParty = this.statementBean.secondParty
      this.payments.forEach(x => {
        this.totalMoney += x.amount
      })
      this.proportion = this.statementBean.payRatioBeforePublish
      this.discount = this.statementBean.payDiscountForUseAmount
      this.invalidmark = this.statementBean.invalidmark
      this.statement = this.statementBean

      // 获取订单基本信息
      getOrder({ orderId: this.orderId }).then(response => {
        this.order = response
        this.order.schedule = GetCurrentSchedule(response.startDate, response.endDate)
      })

      getProductPrice({ orderId: this.orderId }).then(products => {
        this.normal = buildCategories(products.filter(x => x.priceType === 1))
        this.special = buildCategories(products.filter(x => x.priceType === 0))
        this.products = products
      })

      // 获取预估服务费
      // getOrderPredictServicePrice({ orderId: this.orderId }).then(estimate => {
      //   this.EstimateService = estimate
      // })
    },
    handleShowOrder (orderId) {
      this.$refs.orderDetail.init(orderId)
    },
    computeSchedule (products) {
      if (products.length > 0) {
        const product = products[0]
        return GetCurrentSchedule(product.startDate, product.endDate)
      }
    }
  },
  created () {
    this.initPage()
  },
  computed: {
    noDiscountTotal: function () {
      let total = 0
      this.products.forEach(product => {
        total += product.totalSettlementPrice
      })
      return total
    },
    serviceMount: function () {
      const amount = 0
      // this.EstimateService.forEach(element => {
      //   amount += element.totalProducePrice
      //   amount += element.totalInstallPrice
      // })
      return amount
    }
  }

}
</script>
