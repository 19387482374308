<template>
    <div>
        <Card>
            <p slot="title">结算单概况</p>
            <Row>
                <i-col span="8" class="m-b-10">
                    <span  class="title">结算单编码 </span> {{statementBean.code}}
                </i-col>
                 <i-col span="8" class="m-b-10">
                    <span  class="title">发布类型 </span> {{statementBean.contractCategoryName}}
                </i-col>
                 <i-col span="8" class="m-b-10" v-if="statementBean.salePolicy===2">
                    <span  class="title">发布标记 </span> {{statementBean.salePolicyName}}
                </i-col>
                <i-col span="8" class="m-b-10">
                     <span class="title">所属客户 </span>  {{statementBean.advertiserName}}
                </i-col>
                <i-col span="8" class="m-b-10">
                     <span class="title">变更单创建时间 </span>  {{statementBean.createTime}}
                </i-col>
            </Row>

            <Row>
                <i-col span="8">
                     <span class="title">变更单状态 </span> {{statementBean.statusName}}
                </i-col>
                <i-col span="8">
                     <span class="title">最后一次更新时间 </span> {{statementBean.updateTime}}
                </i-col>
            </Row>
        </Card>

        <Divider dashed><h3>变更明细</h3></Divider>
        <Card v-if="changePaymentsBean !== null" :bordered="false" dis-hover>
            <p slot="title">支付方式</p>
            <Row type="flex" align="middle" justify="center" :gutter="9">
                <i-col span="11">
                    <p v-if="changeAmountBean!=null">
                        发布费总金额：<span class="money">{{formatMoney(changeAmountBean.from) }}  </span>
                    </p>
                    <p v-if="changePayDiscountBean!==null">
                        付款方式： <span>{{changePayDiscountBean.from}}</span>
                    </p>
                    <i-table stripe  :data="changePaymentsBean.from" :columns="paymentColumns"></i-table>
                </i-col>
                <i-col span="2">
                    <Icon type="md-arrow-round-forward" size="80" color="green"></Icon>
                </i-col>
                <i-col span="11">
                    <p v-if="changeAmountBean!=null">
                        发布费总金额：<span class="money">{{formatMoney(changeAmountBean.to) }}  </span>
                    </p>
                    <p v-if="changePayDiscountBean!==null">
                        付款方式： <span>{{changePayDiscountBean.to}}</span>
                    </p>
                    <i-table  stripe :data="changePaymentsBean.to" :columns="paymentColumns"></i-table>
                </i-col>
            </Row>
        </Card>
        <Card v-if="changeFilesBean !== null" :bordered="false" dis-hover>
            <p slot="title">附件信息</p>
            <Row type="flex" align="middle" justify="center" :gutter="9">
                <i-col span="11">
                    <i-table stripe  :data="changeFilesBean.from" :columns="fileColumns"></i-table>
                </i-col>
                <i-col span="2">
                    <Icon type="md-arrow-round-forward" size="80" color="green"></Icon>
                </i-col>
                <i-col span="11">
                    <i-table stripe  :data="changeFilesBean.to" :columns="fileColumns"></i-table>
                </i-col>
            </Row>
            <Modal title="查看大图" v-model="showPreview" width="800">
                <img :src="formatImgUrl('fileStementModalImg')" id="fileStementModalImg" v-if="showPreview" style="width: 100%">
            </Modal>
        </Card>
    </div>
</template>

<script>
import { getVersionDetail } from '@/api/scp/statementChange'
import { downloadFileRequest, downloadImgRequest } from '@/utils/download'
import { toMoney } from '@/utils/wnumb_own'

export default {
  props: {
    versionId: [Number, String]
  },
  data () {
    return {
      statementBean: {},
      changeAmountBean: null,
      changePayDiscountBean: null,
      changePaymentsBean: null,
      changeFilesBean: null,
      fileColumns: [
        {
          title: '文件缩略图',
          render: (h, data) => {
            if (data.row.mimeType.startsWith('image')) {
              return h('Icon', {
                props: { type: 'md-images', size: 38, color: '#e96900' },
                style: { 'margin-left': '8px' }
              })
            } else if (this.isMSWord(data.row)) {
              return h('img', { attrs: { src: require('@/assets/images/WinWordLogoSmall.scale-180.png'), height: '50px' } })
            } else if (this.isMSExcel(data.row)) {
              return h('img', { attrs: { src: require('@/assets/images/ExcelLogoSmall.scale-180.png'), height: '50px' } })
            }
          }
        },
        { title: '文件名称', key: 'fileName' },
        { title: '文件大小 (kb)', key: 'length' },
        {
          title: '操作',
          render: (h, data) => {
            let preview
            if (data.row.mimeType.startsWith('image')) {
              preview = h('a',
                {
                  style: {
                    'margin-left': '5px'
                  },
                  on: {
                    click: () => {
                      // this.fileUrl = process.env.VUE_APP_API_URL_V2 + this.downloadUrl + '?fileId=' + data.row.id
                      this.showFileId = data.row.id
                      this.showPreview = true
                    }
                  }
                }, '预览')
            }
            return h('div', [
              h('a', {
                on: {
                  click: () => {
                    this.handleDownload(data.row)
                  }
                }
              }, '下载'), preview
            ])
          }
        }
      ],
      showPreview: false,
      showFileId: '',
      paymentColumns: [
        { title: '付款日期', key: 'date' },
        {
          title: '金额',
          key: 'amount',
          render: (h, data) => {
            return h('span', toMoney(data.row.amount))
          }
        }
      ]
    }
  },
  methods: {
    formatMoney (money) {
      return toMoney(money)
    },
    getDetail () {
      getVersionDetail({ versionId: this.versionId }).then(res => {
        this.statementBean = res
        this.formatChangeDetailBean()
      })
    },
    formatChangeDetailBean () {
      if (this.statementBean.changeDetail.length > 0) {
        // 获取发布费总金额变更
        const amountBean = this.statementBean.changeDetail.find(x => x.field === 'useAmount')
        if (amountBean !== undefined) {
          this.changeAmountBean = amountBean
        }

        // 获取付款比例折扣
        const payDiscountBean = this.statementBean.changeDetail.find(x => x.field === 'payRatioBeforePublish')
        const payRatioBeforeBean = this.statementBean.changeDetail.find(x => x.field === 'payDiscountForUseAmount')
        if (payDiscountBean !== undefined && payRatioBeforeBean !== undefined) {
          this.changePayDiscountBean = {
            from: this.formatPayDiscount(payDiscountBean.from) + ', 媒介使用费打' + (payRatioBeforeBean.from * 10).toFixed(2) + '折',
            to: this.formatPayDiscount(payDiscountBean.to) + ', 媒介使用费打' + (payRatioBeforeBean.to * 10).toFixed(2) + '折'
          }
        }

        // 获取支付方式变更
        const paymentsBean = this.statementBean.changeDetail.find(x => x.field === 'paymentBeanList')
        if (paymentsBean !== undefined) {
          this.changePaymentsBean = paymentsBean
        }

        // 获取附件变更
        const fileBean = this.statementBean.changeDetail.find(x => x.field === 'statementAttachmentFiles')
        if (fileBean !== undefined) {
          this.changeFilesBean = fileBean
        }
      }
    },
    formatPayDiscount (value) {
      let desc = '刊前'
      desc += this.statementBean.dayOffsetBeforePublish + '天付款'
      desc += (value * 100).toFixed(2) + '%'

      if (value < 1) {
        desc += ', 刊后'
        desc += this.statementBean.dayOffsetAfterPublish + '天付款'
        desc += ((1 - value) * 100).toFixed(2) + '%'
      }

      return desc
    },
    formatImgUrl (imgElementId) {
      const imgUrl = process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/contractfile/downloadfile?fileId=' + this.showFileId

      return downloadImgRequest(imgUrl, imgElementId)
    },
    handleDownload (file) {
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/contractfile/downloadfile', { fileId: file.id }, file.fileName)
    },
    isMSWord (file) {
      const mimeTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword']
      return mimeTypes.includes(file.mimeType)
    },
    isMSExcel (file) {
      const mimeTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
      return mimeTypes.includes(file.mimeType)
    }
  },
  created () {
    this.getDetail()
  }
}
</script>
