import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 获取商家笔记列表
 * @param {Object} data
 * @returns
 */
export function getAllNotePage (data) {
  return request({
    url: '/ooh-sf/business/getallnotepage',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取商家信息
 * @param {Object} data
 * @returns
 */
export function getBusiness (data) {
  return request({
    url: '/ooh-sf/business/get',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 获取商家列表
 * @param {Object} data
 * @returns
 */
export function allpage (data) {
  return request({
    url: '/ooh-sf/business/allpage',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 发起导出商家列表
 * @param {Object} data
 * @returns
 */
export function startexportbusiness (data) {
  return request({
    url: '/ooh-sf/business/startexportbusiness',
    method: 'get',
    params: data
  })
}

/**
 * 获取商家列表
 * @param {Object} data
 * @returns
 */
export function getReportPage (data) {
  return request({
    url: '/ooh-sf/business/getReportPage',
    method: 'post',
    data: qs.stringify(data)
  })
}
