<template>
  <div>
    <span class="p-t-5 workplatform-title">基础信息</span>
    <Row>
      <i-col span="8"
        ><span class="title">调整总额 </span
        >{{ formatMoney(adjustmentDetail.amount) }}</i-col
      >

      <i-col span="8"
        ><span class="title">结算日期 </span
        >{{ adjustmentDetail.payDate }}</i-col
      >
      <i-col span="8"
        ><span class="title">调整时间 </span
        >{{ adjustmentDetail.createTime }}</i-col
      >
      <i-col span="8"
        ><span class="title">调整人 </span
        >{{ adjustmentDetail.createUserName }}</i-col
      >
    </Row>
    <Tabs class= 'text-white'>
      <TabPane label="调整项明细" name="detail">
        <span class="hrHead workplatform-title">调整项明细</span>
        <i-table
          stripe
          :columns="columns"
          :data="adjustmentDetail.scpAdjustItemList"
        ></i-table>
        <span class="p-t-10 p-b-10 workplatform-title">付款信息</span>
        <div class="p-b-10">
          按照约定，甲方需在 {{ adjustmentDetail.payDate }} 前支付
          <span class="money">
            {{ formatMoney(adjustmentDetail.amount) }} 元({{
              formatUpperMoney(adjustmentDetail.amount)
            }}
            )
          </span>
        </div>
        <span class="p-t-10 p-b-10 workplatform-title">其他</span>
        <Row>
          <i-col span="24">
            备注：{{
              adjustmentDetail.mark === ""
                ? "无"
                : adjustmentDetail.mark
            }}
          </i-col>
        </Row>
      </TabPane>
      <TabPane label="调整凭证" name="voucher">
        <span class="hrHead workplatform-title">调整项提交时结算信息</span>
        <i-table
          stripe
          :columns="columnsv2"
          :data="adjustmentDetail.preScpItemList"
        ></i-table>
        <span class="p-t-10 p-b-10 workplatform-title"
          >调整项提交时采购信息</span
        >
        <i-table
          stripe
          :columns="columnsv2"
          :data="adjustmentDetail.orderItemList"
        ></i-table>
        <span class="p-t-10 p-b-10 workplatform-title">附件</span>
        <i-table
          stripe
          :columns="fileColumn"
          :data="adjustmentDetail.fileList"
          size="small"
        ></i-table>
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
import { downloadFileRequest } from '@/utils/download'
import { GetCurrentSchedule } from '@/utils/dateFormat'
import { parseUpperMoney } from '@/utils'
import { toMoney } from '@/utils/wnumb_own'

export default {
  props: {
    adjustmentDetail: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      columns: [
        {
          title: '产品名称',
          render: (h, data) => {
            return h('span', data.row.productName)
          }
        },
        {
          title: '档期',
          render: (h, data) => {
            return h(
              'span',
              this.computeSchedule(data.row.startDate, data.row.endDate)
            )
          }
        },
        {
          title: '采购数量',
          render: (h, data) => {
            return h('span', data.row.normalQuantity)
          }
        },
        {
          title: '结算价',
          render: (h, data) => {
            return h('span', data.row.normalSettlementPrice)
          }
        },
        {
          title: '制作费',
          render: (h, data) => {
            return h('span', data.row.totalProducePrice)
          }
        },
        {
          title: '上刊费',
          render: (h, data) => {
            return h('span', data.row.totalInstallPrice)
          }
        }
      ],
      columnsv2: [
        {
          title: '产品名称',
          render: (h, data) => {
            return h('span', data.row.productName)
          }
        },
        {
          title: '档期',
          render: (h, data) => {
            return h(
              'span',
              this.computeSchedule(data.row.startDate, data.row.endDate)
            )
          }
        },
        {
          title: '采购数量',
          render: (h, data) => {
            return h('span', data.row.normalQuantity + data.row.productUnit)
          }
        },
        {
          title: '订单价',
          render: (h, data) => {
            return h('span', toMoney(data.row.normalUsePrice))
          }
        },
        {
          title: '结算价',
          render: (h, data) => {
            return h('span', toMoney(data.row.normalSettlementPrice))
          }
        },
        {
          title: '制作费',
          render: (h, data) => {
            return h('span', toMoney(data.row.totalProducePrice))
          }
        },
        {
          title: '上刊费',
          render: (h, data) => {
            return h('span', toMoney(data.row.totalInstallPrice))
          }
        }
      ],
      fileColumn: [
        {
          title: '文件缩略图',
          render: (h, data) => {
            if (data.row.mimeType.startsWith('image')) {
              return h('Icon', {
                props: { type: 'md-images', size: 28, color: '#e96900' },
                style: { 'margin-left': '8px' }
              })
            } else if (this.isMSWord(data.row)) {
              return h('img', {
                attrs: {
                  src: require('@/assets/images/WinWordLogoSmall.scale-180.png'),
                  height: '30px'
                }
              })
            } else if (this.isMSExcel(data.row)) {
              return h('img', {
                attrs: {
                  src: require('@/assets/images/ExcelLogoSmall.scale-180.png'),
                  height: '30px'
                }
              })
            }
          }
        },
        { title: '文件名称', key: 'fileName' },
        { title: '文件大小 (kb)', key: 'length' },
        {
          title: '下载',
          render: (h, params) => {
            return h(
              'a',
              {
                on: {
                  click: () => {
                    this.downloadFile(params.row)
                  }
                }
              },
              '下载'
            )
          }
        }
      ]
    }
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },
    formatUpperMoney (number) {
      return parseUpperMoney(number)
    },
    computeSchedule (startDate, endDate) {
      return GetCurrentSchedule(startDate, endDate)
    },
    downloadFile (file) {
      downloadFileRequest(
        process.env.VUE_APP_API_URL_V2 +
          '/ooh-scp/v1/scpbusinessadjust/downloadadjusttempfile',
        { fileId: file.id },
        file.fileName
      )
    },
    isMSWord (file) {
      const mimeTypes = [
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword'
      ]
      return mimeTypes.includes(file.mimeType)
    },
    isMSExcel (file) {
      const mimeTypes = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ]
      return mimeTypes.includes(file.mimeType)
    }
  }
}
</script>
