<template>
  <div>
    <detail :feeitemType="1" :billId="id"/>

  </div>
</template>

<script>
import detail from '@/components/settlement/detailSettlement'

export default {
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  components: {
    detail
  }
}
</script>
