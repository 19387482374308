<template>
  <div>
    <template v-if="task.processtypeFeature === 1">
      <Alert show-icon type="warning" >
        <h2 class="text-orange">合同作废申请</h2>
        <div slot="desc">
          <Row>
            <i-col span="8">状态： {{invalidBean.invalidStatusName}}</i-col>
            <i-col span="8">创建时间： {{invalidBean.createTime}}</i-col>
            <i-col span="8">最后更新时间： {{invalidBean.invalidTime}}</i-col>
          </Row>
          <Row>
            <i-col span="8">最后操作人： {{invalidBean.invalidUserName}}</i-col>
            <i-col span="8">描述： {{invalidBean.invalidMark}}</i-col>
          </Row>
          <p v-if="invalidBean.fileList&&invalidBean.fileList.length>0">
            作废附件清单:
            <i-table stripe  :columns="approvalFileColumn" :data="invalidBean.fileList" size="small"></i-table>
          </p>
        </div>
      </Alert>
    </template>

    <Card :bordered="false" dis-hover>
      <Row>
      <i-col span="24" class="text-right">
        <i-button type="primary" class="m-r-5" size="small" @click="handleShowOrderDetail">查看订单详情</i-button>
      </i-col>
    </Row>

      <contract-detail  ref="contractDetail" :id="id" :versionId="id" @on-lowerSettlementPrice="onlowerSettlementPrice"></contract-detail>
    </Card>
  </div>
</template>

<script>
import { downloadFileRequest } from '@/utils/download'
import { getContractInvalidInfo } from '@/api/scp/contract'
import ContractDetail from '@/components/contract/contractDetail/Contract'

export default {
  props: {
    id: [Number, String],
    task: {
      type: Object
    }
  },
  components: {
    ContractDetail
  },
  data () {
    return {
      invalidBean: {},
      approvalFileColumn: [
        {
          title: '文件缩略图',
          render: (h, data) => {
            if (data.row.mimeType.startsWith('image')) {
              return h('Icon', {
                props: { type: 'md-images', size: 28, color: '#e96900' },
                style: { 'margin-left': '8px' }
              })
            } else if (this.isMSWord(data.row)) {
              return h('img', { attrs: { src: require('@/assets/images/WinWordLogoSmall.scale-180.png'), height: '30px' } })
            } else if (this.isMSExcel(data.row)) {
              return h('img', { attrs: { src: require('@/assets/images/ExcelLogoSmall.scale-180.png'), height: '30px' } })
            }
          }
        },
        { title: '文件名称', key: 'fileName' },
        { title: '文件大小 (kb)', key: 'length' },
        {
          title: '下载',
          render: (h, params) => {
            return h('a',
              {
                style: { fontSize: '20px' },
                on: {
                  click: () => {
                    this.downloadFile(params.row)
                  }
                }
              },
              [h('Icon', { props: { type: 'md-cloud-download' } })]
            )
          }
        }
      ]
    }
  },
  created () {
    if (this.task.processtypeFeature === 1) {
      this.loadInvalidInfo()
    }
  },
  methods: {
    onlowerSettlementPrice (val) {
      this.$emit('on-lowerSettlementPrice', val)
    },

    handleShowOrderDetail () {
      this.$refs.contractDetail.handleShowMultOrder()
    },
    loadInvalidInfo () {
      getContractInvalidInfo({ contractId: this.id }).then(res => {
        if (res.invalidHistoryList && res.invalidHistoryList.length > 0) {
          this.invalidBean = res.invalidHistoryList[res.invalidHistoryList.length - 1]
        }
      })
    },
    downloadFile (file) {
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/contract/downloadcontractinvalidfile', { fileId: file.id }, file.fileName)
    },
    isMSWord (file) {
      const mimeTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword']
      return mimeTypes.includes(file.mimeType)
    },
    isMSExcel (file) {
      const mimeTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
      return mimeTypes.includes(file.mimeType)
    }
  }
}
</script>
