<template>
  <div>
    <Collapse simple v-model="defaultValue">
     <Panel name="1">
        <b>调整项详情</b>
        <template slot="content">
          <AdjustmentItemDetail
            v-if="currentadjustmentDetail !== null"
            :adjustmentDetail="currentadjustmentDetail"
          />
        </template>
      </Panel>
      <Panel name="2" v-if="currentadjustmentDetail&&currentadjustmentDetail.bid">
        <b>结算单详情</b>
        <statementDetailComponent
          slot="content"
           :isApproval="true"
          :id="currentadjustmentDetail.bid"
        ></statementDetailComponent>
      </Panel>

    </Collapse>
  </div>
</template>

<script>
import AdjustmentItemDetail from '@/components/statement/AdjustmentItemDetail'
import statementDetailComponent from '@/components/statement/StatementDetailComponent'
import { getadjust } from '@/api/scp/scpbusinessadjust'

export default {
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  components: {
    AdjustmentItemDetail,
    statementDetailComponent
  },
  data () {
    return {
      defaultValue: '1',
      currentadjustmentDetail: null
    }
  },
  created () {
    getadjust({ adjustId: this.id }).then((res) => {
      this.currentadjustmentDetail = res
    })
  }
}
</script>
