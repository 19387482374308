<template>
  <div>
    <p class="hrHead ">
      <span  class="title">订单编号: </span>{{ orderBean.code }}
      <span  class="title">， 有赞编号：</span><a  @click="handleShowDetail">{{ orderBean.yzOrderId }}</a>

      <a class="m-l-10" @click="handleShowOrder(orderBean.id)">查看订单明细</a>
    </p>

    <i-table
      stripe
      size="small"
      :data="displaceMediaList"
      :columns="displaceMediaColumns"
    ></i-table>

    <!-- 弹窗 订单详情 -->
    <OrderDetail ref="orderDetail" />
    <Detail ref="mediaOrderDetail" />
  </div>
</template>

<script>
import OrderDetail from '@/components/order/common/ModalOrderDetail'
import Detail from '@/components/mediaOrder/Detail'
import { getOrderList } from '@/api/order/order'
import { getProductDetailPageByOrder } from '@/api/order/productdetail'
import { toMoney } from '@/utils/wnumb_own'

export default {
  props: {
    orderId: {
      type: [Number, String],
      required: true
    }
  },
  components: {
    OrderDetail, Detail
  },
  data () {
    return {
      orderBean: {},
      displaceMediaList: [],
      displaceMediaColumns: [
        { title: '资源编号', key: 'resourceCode' },
        { title: '资源类型', key: 'resourcetypeName' },
        { title: '所属资产', key: 'assetName' },
        { title: '所属站点', key: 'stationName' },
        {
          title: '投放档期',
          key: 'schedule',
          render: (h, data) => {
            return h('div', [
              h('span', data.row.startDate),
              h(
                'span',
                ' 至 '
              ),
              h('span', data.row.endDate)
            ])
          }
        },
        {
          title: '媒体刊例',
          key: 'usePrice',
          render: (h, data) => {
            return h('div', [
              h('span', { class: 'money' }, this.formatMoney(data.row.useprice))
            ])
          }
        }
      ]
    }
  },
  mounted () {
    this.initBaseData()
  },
  methods: {
    initBaseData () {
      getOrderList({ orderIdStr: JSON.stringify([this.orderId]) }).then(res => {
        if (res.length > 0) {
          this.orderBean = res[0]
        }
      })

      const resourceQuery = {
        pageNumber: 1,
        pageSize: 100,
        orderId: this.orderId
      }
      getProductDetailPageByOrder(resourceQuery).then(res => {
        this.displaceMediaList = res.list
      })
    },
    handleShowOrder (orderId) {
      // 根据菜单动态跳转链接
      this.$refs.orderDetail.init(orderId)
    },
    formatMoney (number) {
      return toMoney(number)
    },
    handleShowDetail () {
      this.$refs.mediaOrderDetail.showModal(this.orderBean.yzOrderId)
    }
  },
  watch: {
    id () {
      this.initBaseData()
    }
  }
}
</script>
