<template>
  <div>
  <Tabs @on-click="handleTabClick" v-if="loadType">
    <TabPane label="结算单详情">
      <div class="text-center workplatform-title">媒体广告发布结算单</div>
      <p>
        <span  class="title">结算单编码 </span> {{ statement.code }}
         <Tag
          class="m-l-5"
          color="blue"
          >{{statement.contractCategoryName}}</Tag
        >
         <Tag
          class="m-l-5"
          color="green"
          v-if="statement.salePolicy===2"
          >{{statement.salePolicyName}}</Tag
        >
      </p>
        <!-- <p><span  class="title">发布类型 </span> {{statement.contractCategoryName}}</p>
        <p v-if="statement.salePolicy===2"><span  class="title">发布标记 </span> {{statement.salePolicyName}}</p> -->
      <p><span class="title">甲方 </span> {{ firstParty }}</p>
      <p><span  class="title">乙方 </span> {{ secondParty }}</p>
      <p>
        按照双方签订的年度广告发布《合作协议》（以下简称“年度合同”），以下列表为本次乙方代理
        <span class="text-orange">{{ order.brandName }}</span>
        广告发布信息，发布日期为：
        <span class="text-orange">{{ order.schedule }}</span>
      </p>
      <div v-show="normal1.length > 0" style="margin-top:10px;">
        <div v-for="(category, index) in normal1" :key="index">
          <!-- <Tag color="blue"> {{ category.productKindName }} </Tag> -->
          <Timeline>
            <TimelineItem v-for="(schedule, i) in category.schedule" :key="i">
              <h4>{{ computeSchedule(schedule) }}</h4>
              <NewPro :products="schedule" :dataType="'normal'"></NewPro>
            </TimelineItem>
          </Timeline>
        </div>
      </div>
      <div v-show="special1.length > 0" style="margin-top:10px;">
        <Divider dashed>特惠资源</Divider>
        <div v-for="(category, index) in special1" :key="index">
          <!-- <Tag color="blue"> {{ category.productKindName }} </Tag> -->
          <Timeline>
            <TimelineItem v-for="(schedule, i) in category.schedule" :key="i">
              <h4>{{ computeSchedule(schedule) }}</h4>
              <NewPro :products="schedule" :dataType="'recommend'"></NewPro>
            </TimelineItem>
          </Timeline>
        </div>
      </div>

      <Card :bordered="false" dis-hover v-if="paymentMethod===1">
        <p slot="title">付款信息</p>
        <p>
          经过核算，乙方的广告投放共产生了费用：
          <span class="money"
            >{{ formateMoney(noDiscountTotal) }}, （人民币
            {{ formateUpperMoney(noDiscountTotal) }} ） </span
          >
          ,乙方选择刊前付款
          <span class="discount">{{ (proportion * 100).toFixed(2) }}%</span>
          ,所以乙方享有媒介使用费折扣
          <span class="discount">{{ (discount * 10).toFixed(2) }}折</span
          >，故共需付费
          <span class="money">
            {{ formateMoney(totalMoney) }}, （人民币
            {{ formateUpperMoney(totalMoney) }} ）
          </span>
        </p>
        <p v-show="payments.length > 0">
          乙方应按下列期限及方式将合同款如期支付给甲方：
        </p>
        <ol>
          <li v-for="(item, i) in payments" :key="12545 + i">
            {{ item.date }} 前支付
            <span class="money"
              >{{ formateMoney(item.amount) }} 元（大写：人民币
              {{ formateUpperMoney(item.amount) }}）</span
            >
          </li>
        </ol>
      </Card>
      <Card :bordered="false" dis-hover v-if="paymentMethod===2">
        <p slot="title">付款信息</p>
        <p>
          经过核算，乙方的广告投放共产生了费用：
          <span class="money"
            >{{ formateMoney(noDiscountTotal) }}, （人民币
            {{ formateUpperMoney(noDiscountTotal) }} ） </span
          >
         ,乙方选择储值抵扣,所以乙方无需支付媒介使用费，故共需付服务费
          <span class="money">
            {{ formateMoney(totalMoney) }}, （人民币
            {{ formateUpperMoney(totalMoney) }} ）
          </span>
        </p>
        <p v-show="payments.length > 0">
          乙方应按下列期限及方式将合同款如期支付给甲方：
        </p>
        <ol>
          <li v-for="(item, i) in payments" :key="12545 + i">
            {{ item.date }} 前支付
            <span class="money"
              >{{ formateMoney(item.amount) }} 元（大写：人民币
              {{ formateUpperMoney(item.amount) }}）</span
            >
          </li>
        </ol>
      </Card>
      <!-- <Card :bordered="false" dis-hover>
        <p slot="title">附加信息</p>
        <ol>
          <li>
            本次广告发布服务费（即画面制作费和上下刊费）根据乙方工作人员在交易平台上提交的服务需求累积计算，服务费总金额将在
            <span class="text-orange"> 服务费明细</span>
            中显示。乙方应在画面上刊前结清服务费，否则甲方有权不予发布广告。
          </li>
          <li>
            根据乙方的发布需求，甲方预估乙方在发布的第一个档期产生的服务费为人民币
            <span class="money"
              >{{ formateMoney(serviceMount) }}（大写：人民币
              {{ formateUpperMoney(serviceMount) }}）</span
            >。但最终费用以实际发生的为准。
          </li>
        </ol>
      </Card> -->
    </TabPane>
    <TabPane label="服务费明细"  v-if="isApproval">
      <service-fees
        :serviceFees="statement.realServicefeeList || []"
        :statementBean="statement"
      ></service-fees>
    </TabPane>
<TabPane label="调整项" name="adjustment" v-if="!isApproval">
      <AdjustmentItem  :id="id" :orderId="orderId" :versionId="versionId" v-if="versionId"></AdjustmentItem>
    </TabPane>
    <TabPane label="付款详情" name="payment"  v-if="!isApproval">
      <payments :id="id" v-if="showPayments" :serviceType="2"></payments>
    </TabPane>
    <TabPane label="开票详情" name="invoice"  v-if="!isApproval">
      <invoice :id="id" v-if="showInvoice"></invoice>
    </TabPane>
    <TabPane label="变更记录" name="record" v-if="!isApproval">
      <change-record
        :id="id"
        :status="'结算单'"
        v-if="showRecord"
      ></change-record>
    </TabPane>
    <TabPane label="作废详情" v-if="!isApproval">
      <invalid-detail  :relateId="id" :processType="7"></invalid-detail>
    </TabPane>
    <TabPane v-if="versionId !== 0" label="附件">
      <!-- <files type="STATEMENT_ATTACHMENT_FILE" :relateId="versionId"></files> -->
       <!-- <files
            :fileList="files"
            :downloadUrl="'/ooh-scp/v1/contractfile/downloadfile'"
          ></files> -->
          <div v-if="versionId">
          <div class="  workplatform-title">销售个人上传</div>
          <files
            :fileList="files"
            :downloadUrl="'/ooh-scp/v1/contractfile/downloadfile'"
          ></files>
          <div class="m-t-10 workplatform-title">管理员上传</div>
          <files
            :fileList="signfiles"
            :downloadUrl="'/ooh-scp/v1/contractfile/downloadfile'"
          ></files>
        </div>

    </TabPane>
  </Tabs>

  <statement-detail
    v-else-if="statement.code && !loadType"
    :statementBean="statement"
  />
</div>
</template>

<script>
import InvalidDetail from '@/components/approval/InvalidDetail'
import files from '@/components/common/FilesV2'
import Payments from '../contract/contractDetail/detail/Payments'
import Invoice from '../contract/contractDetail/detail/Invoice'

import serviceFees from './ServiceFees'
import statementDetail from './StatementDetail'
import AdjustmentItem from './AdjustmentItem'
import ChangeRecord from '../contract/contractDetail/detail/ChangeRecord'
import NewPro from '@/components/statement/NewPro'

import { toMoney } from '@/utils/wnumb_own'
import { parseUpperMoney } from '@/utils'
import { GetCurrentSchedule } from '@/utils/dateFormat'

import { getOrderScheduleCategoryList } from '@/api/scp/statementorder'
import {
  getStatementDetailByVersionId,
  getStatementInvalidmark
} from '@/api/scp/statement'
import { getOrder } from '@/api/order/order'
import { getFileList } from '@/api/scp/contractfile'

export default {
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    versionId: {
      type: Number,
      required: true
    },
    loadType: {
      type: [Boolean],
      default: true
    },
    isApproval: {
      type: [Boolean],
      default: false
    }
  },
  components: {
    statementDetail,
    InvalidDetail,
    Invoice,
    Payments,
    serviceFees,
    NewPro,
    files,
    AdjustmentItem,
    ChangeRecord
  },
  data () {
    return {
      firstParty: '',
      secondParty: '',
      order: {},
      payments: [],
      totalMoney: 0,
      statementId: this.$route.params.id,
      orderId: 0,
      status: 0, // 结算单状态

      paymentMethod: 1,

      invalidmark: {},
      proportion: 0,
      discount: 0,
      statement: {},
      showHistory: false,
      showInvoice: false,
      showRecord: false,
      showPayments: false,
      EstimateService: [],
      mainData: [],
      normal: [],
      normal1: [],
      special1: [],
      special: [],
      products: [],
      files: [],
      signfiles: []
    }
  },
  methods: {
    formateMoney (number) {
      return toMoney(number)
    },
    formateUpperMoney (money) {
      return parseUpperMoney(money)
    },
    getDetail () {
      getStatementDetailByVersionId({ versionId: this.versionId || this.id }).then((res) => {
        this.orderId = res.orderIdList[0]
        this.payments = res.paymentBeanList
        this.firstParty = res.firstParty
        this.secondParty = res.secondParty
        this.payments.forEach((x) => {
          this.totalMoney += x.amount
        })
        this.paymentMethod = res.paymentMethod
        this.proportion = res.payRatioBeforePublish
        this.discount = res.payDiscountForUseAmount
        this.statement = res
        this.status = res.status
        this.$emit('on-inited', res)
        if (res.status === -1) {
          getStatementInvalidmark({ statementId: this.versionId }).then((invalid) => {
            this.invalidmark = invalid
          })
        }
        getOrder({ orderId: this.orderId }).then((response) => {
          this.order = response
          this.order.schedule = GetCurrentSchedule(
            response.startDate,
            response.endDate
          )
        })
        getOrderScheduleCategoryList({
          statementId: this.id,
          version: this.versionId
        }).then((response) => {
          this.mainData = response[0].signPriceDetail
          this.normal1 = []
          this.special1 = []
          response[0].signPriceDetail.forEach((items) => {
            this.formatData(items)
          })
        })

        this.getFiles()
      })
    },

    getFiles () {
      if (this.versionId) {
        getFileList({ relateId: this.versionId, type: 'STATEMENT_ATTACHMENT_FILE' }).then(res => {
          this.files = res.map(x => {
            return Object.assign({}, x, { url: process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/contractfile/downloadfile?fileId=' + x.id })
          })
        })
        getFileList({ relateId: this.versionId, type: 'STATEMENT_MANAGE_ATTACHMENT_FILE' }).then(res => {
          this.signfiles = res.map(x => {
            return Object.assign({}, x, { url: process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/contractfile/downloadfile?fileId=' + x.id })
          })
        })
      }
    },
    formatData (item) {
      if (item.signNormalQuantity > 0) {
        item.quantity = item.signNormalQuantity
        item.totalUsePrice = item.normalUsePrice
        item.totalSettlementPrice = item.normalSettlementPrice// item.normalSettlementPrice
        let proCheck = -1
        this.normal1.forEach((pro) => {
          if (item.productKindName === pro.productKindName) {
            proCheck = 0
            let check = -1
            pro.schedule.forEach((products, index) => {
              if (item.startDate === products[0].startDate) {
                check = index
              }
            })
            if (check === -1) {
              pro.schedule.push([item])
            } else {
              pro.schedule[check].push(item)
            }
          }
        })
        if (proCheck === -1) {
          const main = {
            productKindName: item.productKindName,
            schedule: [[item]]
          }
          this.normal1.push(main)
        }

        // this.normal1=
      }
      if (item.recommendSignQuantity > 0) {
        item.quantity = item.recommendSignQuantity
        item.totalUsePrice = item.recommendUsePrice
        item.totalSettlementPrice = item.recommendSettlementPrice
        let proCheck = -1
        this.special1.forEach((pro) => {
          if (item.productKindName === pro.productKindName) {
            proCheck = 0
            let check = -1
            pro.schedule.forEach((products, index) => {
              if (item.startDate === products[0].startDate) {
                check = index
              }
            })
            if (check === -1) {
              pro.schedule.push([item])
            } else {
              pro.schedule[check].push(item)
            }
          }
        })
        if (proCheck === -1) {
          const main = {
            productKindName: item.productKindName,
            schedule: [[item]]
          }
          this.special1.push(main)
        }
      }
    },
    computeSchedule (products) {
      if (products.length > 0) {
        const product = products[0]
        return GetCurrentSchedule(product.startDate, product.endDate)
      }
    },
    handleTabClick (name) {
      if (name === 'invoice') {
        this.showInvoice = true
      }
      if (name === 'payment') {
        this.showPayments = true
      }
      if (name === 'record') {
        this.showRecord = true
      }
    }
  },
  created () {
    this.getDetail()
  },
  watch: {
    id () {
      this.getDetail()
    }
  },
  computed: {
    noDiscountTotal: function () {
      let total = 0
      this.mainData.forEach((product) => {
        // if (product.priceType === 0) {
        total += product.normalSettlementPrice
        total += product.signServicePrice
        // } else {
        //   total += product.totalUsePrice
        // }
      })

      return total
    },
    serviceMount: function () {
      const amount = 0
      // this.EstimateService.forEach(element => {
      //   amount += element.totalProducePrice
      //   amount += element.totalInstallPrice
      // })
      return amount
    }
  }
}
</script>
