<template>
  <div>
    <Row class="text-right m-b-5">
      <i-button
        size="small"
        type="success"
        @click="createAdjustment"
        >新建调整项</i-button
      >
    </Row>

    <i-table stripe :columns="adjustmentItemcolumns" :data="list"></i-table>
    <Modal v-model="adjustmentDetail" width="800" title="调整项详情" v-if="currentadjustmentDetail.adjustId">
      <AdjustmentDetail :adjustmentDetail="currentadjustmentDetail"></AdjustmentDetail>
    </Modal>
    <AddAdjustment ref="createAdjustmentModal" :id="id" :versionId="versionId" :orderId="orderId" @finishConfirm="finishConfirm"></AddAdjustment>

  </div>
</template>

<script>
import { getadjustlist, getadjust } from '@/api/scp/scpbusinessadjust'

// import { buildCategories } from '@/utils/orderUtils'
import { GetCurrentSchedule } from '@/utils/dateFormat'
import { toMoney } from '@/utils/wnumb_own'
import { formatStatementStatus } from '@/utils/tagStatus'

import AddAdjustment from './AddAdjustment'
import AdjustmentDetail from './AdjustmentItemDetail'

export default {
  components: {
    AddAdjustment,
    AdjustmentDetail
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    versionId: {
      type: [Number, String],
      required: true
    },
    orderId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      list: [
      ],
      adjustmentItemcolumns: [
        {
          title: '调整金额',
          render: (h, data) => {
            return h('span', data.row.amount)
          }
        },
        {
          title: '结算日期',
          render: (h, data) => {
            return h('span', data.row.payDate)
          }
        },
        {
          title: '调整人',
          render: (h, data) => {
            return h('span', data.row.createUserName)
          }
        },
        {
          title: '调整时间',
          render: (h, data) => {
            return h('span', data.row.createTime)
          }
        },
        {
          title: '状态',
          render: (h, data) => {
            return formatStatementStatus(
              h,
              data.row.status,
              data.row.statusName
            )
          }
        },
        {
          title: '操作',
          render: (h, data) => {
            const detailButton = h(
              'a',
              {
                style: { 'margin-left': '5px' },
                on: {
                  click: () => {
                    this.showDetail(data.row.adjustId)
                  }
                }
              },
              '调整详情'
            )
            const returnBtn = [detailButton]
            return h('div', returnBtn)
          }
        }
      ],
      adjustmentDetail: false,

      currentadjustmentDetail: {}
    }
  },
  created () {
    if (this.id) {
      this.initData()
    }
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },
    initData () {
      // 获取调整线列表
      getadjustlist({ bid: this.id, versionId: this.versionId }).then(res => {
        this.list = res
      })
    },
    createAdjustment () {
      this.$refs.createAdjustmentModal.initData()
    },
    computeSchedule (startDate, endDate) {
      return GetCurrentSchedule(startDate, endDate)
    },
    // modaltype:1表示凭证，2表示详情
    showDetail (adjustId) {
      const that = this
      if (that.currentadjustmentDetail.adjustId !== adjustId) {
        getadjust({ adjustId: adjustId }).then(res => {
          that.currentadjustmentDetail = res

          that.adjustmentDetail = true
        })
      } else {
        that.adjustmentDetail = true
      }
    },
    finishConfirm () {
      this.initData()
    }
  },
  watch: {
    id () {
      this.initData()
    }
  }
}
</script>
