<template>
  <div>
    <Collapse
      simple
      v-model="defaultValue"
    >
      <Panel name="1">
        <b>播单列表</b>
        <div slot="content">
          <Table
            stripe
            size="small"
            :data="tableData"
            :columns="tableColumns"
          ></Table>
        </div>
      </Panel>
    </Collapse>
    <Modal
      v-model="detailModal"
      footer-hide
      width="800"
    >
      <p
        slot="header"
        class="text-center"
      >{{curProgramInfo.resourceCode}} 编播详情</p>
      <div>
        <Table
          stripe
          size="small"
          height="600"
          :data="detailList"
          :columns="detailColumns"
          :loading="detailLoading"
        ></Table>
      </div>
    </Modal>
    <video-show
      ref="videoShow"
      domFlag="playList"
    ></video-show>
  </div>
</template>

<script>
import VideoShow from '@/components/eleScreen/components/VideoShow.vue'
import { getApprovalPrograms, listProgramDetails } from '@/api/inventory/editing'
export default {
  props: {
    id: [Number, String],
    task: {
      type: Object
    }
  },
  components: {
    VideoShow
  },
  data () {
    return {
      defaultValue: '1',
      tableData: [],
      tableColumns: [
        { title: '资产', key: 'assetName' },
        { title: '站点', key: 'stationName' },
        { title: '资源编号', key: 'resourceCode' },
        { title: '播出日期', align: 'center', key: 'publishDay' },
        {
          title: '起止时间',
          align: 'center',
          render: (h, params) => {
            return h('span', `${params.row.startTime} - ${params.row.endTime}`)
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('a', {
              on: {
                click: () => {
                  this.handleViewDetail(params.row)
                }
              }
            }, '详情')
          }
        }
      ],
      detailModal: false,
      curProgramInfo: {},
      detailLoading: false,
      sumDuration: 0,
      detailList: [],
      detailColumns: [
        { title: '#', key: 'index', width: 80 },
        {
          title: '客户',
          key: 'advertiserName',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.advertiserName || '-')
          }
        },
        {
          title: '品牌',
          key: 'brandName',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.brandName || '-')
          }
        },
        {
          title: '行业',
          key: 'industryName',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.industryName || '-')
          }
        },
        {
          title: '类型',
          key: 'type',
          align: 'center',
          render: (h, params) => {
            switch (params.row.type) {
              case 1:
                return h('Tag', {
                  props: { color: 'primary' }
                }, '商业')
              case 2:
                return h('Tag', {
                  props: { color: 'success' }
                }, '公益')
              case 3:
                return h('Tag', {
                  props: { color: 'blue' }
                }, '小微')
              case 4:
                return h('Tag', '节目')
              default:
                return h('Tag', {
                  props: { color: 'error' }
                }, '未知')
            }
          }
        },
        {
          title: '投放视频',
          render: (h, params) => {
            return h('a', {
              on: {
                click: () => {
                  const postData = {
                    fileName: params.row.fileName,
                    filePath: params.row.fileKey
                  }
                  this.$refs.videoShow.show(postData)
                }
              }
            }, params.row.fileName)
          }
        },
        { title: '时长（秒）', key: 'duration', align: 'center' }
      ]
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    initData () {
      getApprovalPrograms({ relateId: this.id }).then(res => {
        if (res && !res.errcode) {
          this.tableData = res
        } else {
          this.tableData = []
        }
      })
    },
    handleViewDetail (params) {
      this.curProgramInfo = params
      this.getDetailData()
      this.detailModal = true
    },
    /**
     * 获取播单详情
     */
    getDetailData () {
      this.detailList = []
      this.detailLoading = true
      listProgramDetails({ programId: this.curProgramInfo.id }).then(res => {
        this.detailLoading = false
        if (res && !res.errcode) {
          this.detailList = res.details
          this.sumDuration = res.sumDuration
        } else {
          this.detailList = []
          this.sumDuration = 0
        }
      })
    }
  }
}
</script>
