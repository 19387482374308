<template>
  <div>
    <div class="p-b-10">
      <div class="workplatform-title">{{  billDetail.name||' - ' }}({{billDetail.code}})</div>

      <Row v-if="feeitemType === 2">
          <i-col span="8">
            <span class="title">公司 </span>{{ billDetail.purchaserCompanyName }}
          </i-col>
          <i-col span="8">
            <span class="title">供应商 </span>{{ billDetail.supplierCompanyName }}
          </i-col>
          <i-col span="8">
            <span class="title">创建时间 </span>{{ billDetail.createTime }}
          </i-col>
        </Row>
        <Row  v-else>
          <i-col span="8">
            <span class="title">结算公司 </span>{{ billDetail.agentCompanyName }}
          </i-col>
          <i-col span="8">
            <span class="title">创建时间 </span>{{ billDetail.createTime }}
          </i-col>
        </Row>
        <Row >
          <i-col span="24">
            <span  class="title">最终结算金额 </span
            ><span class="money">{{
              formatMoney(billDetail.billAmount)
            }}</span>
          </i-col>
        </Row>
        <Row >
          <i-col span="8">
            <span class="title"> 计费项金额  </span>
            <span class="money">{{ formatMoney(billDetail.normalAmount) }}</span>
          </i-col>
          <i-col span="8">
            <span class="title"> 调整项⾦额  </span>
             <span class="money">{{ formatMoney(billDetail.adjustAmount) }}</span>
          </i-col>
          <i-col span="8">
            <span class="title"> 罚款项金额 </span>
             <span class="money">{{ formatMoney(billDetail.penaltyAmount) }}</span>
          </i-col>
        </Row>
        <Row v-if="feeitemType===2">
          <i-col span="24">
            <span class="title">资产 </span>
            {{ billDetail.zones || "-" }}
          </i-col>
        </Row>
        <Row>
          <i-col span="24">
            <span class="title"> 备注: </span>
            {{ billDetail.mark || "-" }}
          </i-col>
        </Row>
    </div>
    <div class="p-t-10">
      <div class="workplatform-title">费用清单列表</div>
      <Tabs>
        <TabPane label="计费项">
          <template>
            <Row class="m-b-5">
              <i-col span="16">
                <Row :gutter="8">
                  <i-col span="18">
                    <i-input
                      type="text"
                      size="small"
                      v-model="feeitemQuery.keyword"
                      clearable
                      placeholder="关键字"
                    ></i-input>
                  </i-col>
                </Row>
              </i-col>
            </Row>
            <Table
              stripe
              class="m-t-5"

              :max-height="500"
              :data="feeitempage"
              :columns="column"
              :summary-method="handleSummary"
              show-summary
            ></Table>
            <div class="paging_style">
          <Page size="small"
            :total="feeitemTotal"
            :page-size="feeitemQuery.pageSize"
            show-total
            show-elevator
            show-sizer
            :page-size-opts="[15, 50, 100, 200, 500]"
            @on-page-size-change="handlePageSizeChanged"
            :current="feeitemQuery.pageNumber"
            @on-change="feeitemChangePage"
          ></Page>
        </div>
          </template>
        </TabPane>
        <TabPane label="调整项">
          <template>
             <Row class="m-b-5">
              <i-col span="24" class="text-right">
                <Button type="info" size="small" class="m-r-5" @click="modaluploadFile=true"
                  >附件信息</Button>
              </i-col>
            </Row>
            <Table
              stripe
              :data="adjustfeeitems"
              :max-height="500"
              :columns="adjustfeeitemColumn"
              :summary-method="handleSummary"
              show-summary
            ></Table>
             <div class="paging_style">
          <Page size="small"
            :total="adjustfeeitemTotal"
            :page-size="adjustfeeitemQuery.pageSize"
            show-total
            show-elevator
            show-sizer
            :page-size-opts="[15, 50, 100, 200, 500]"
            @on-page-size-change="handleadjustfeeitemPageSizeChanged"
            :current="adjustfeeitemQuery.pageNumber"
            @on-change="adjustfeeitemChangePage"
          ></Page>
        </div>
          </template>
        </TabPane>
        <TabPane label="罚款项">
          <template>

            <Table
              stripe
              :data="fines"
              :max-height="500"
              :columns="fineColumns"
              :summary-method="handleSummary"
              show-summary
            ></Table>
             <div class="paging_style">
          <Page size="small"
            :total="fineTotal"
            :page-size="fineQuery.pageSize"
            show-total
            show-elevator
            show-sizer
            :page-size-opts="[15, 50, 100, 200, 500]"
            @on-page-size-change="handlefinePageSizeChanged"
            :current="fineQuery.pageNumber"
            @on-change="fineChangePage"
          ></Page>
        </div>
          </template>
        </TabPane>
        <TabPane label="含税汇总" v-if="feeitemType === 2">
          <template>
            <Table
              stripe
              :data="summary"
              :max-height="500"
              :columns="summaryColumns"
              :summary-method="handleSummary"
              show-summary
            ></Table>
          </template>
        </TabPane>
      </Tabs>
    </div>
    <Modal v-model="modaluploadFile" width="800" :footer-hide="true" title="调整项附件">
      <div>
         <fileUpload type="STATEMENT_BILL_ADJUST" :relateId="billId" :allowUpload="false" :format="fileTypes" ></fileUpload>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  getbilloutline,
  getfeeitempage,
  getmspfeeitempage,
  getadjustfeeitempage,
  getbillamountstructurewithtaxratio
} from '@/api/statement/bill'
import fileUpload from '@/components/common/FileUpload'
import { getPenaltyPage } from '@/api/statement/penalty'
import { toMoney } from '@/utils/wnumb_own'
import { GetCurrentSchedule } from '@/utils/dateFormat'

export default {
  components: {
    fileUpload
  },
  props: {
    feeitemType: {
      type: Number,
      default: 1
    },
    billId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,
      billDetail: {},
      feeitempage: [],
      column: [],

      modaluploadFile: false,
      fileTypes: ['img', 'jpg', 'png', 'doc', 'docx', 'pdf', 'xls', 'xlsx'],

      feeitemTotal: 0,
      feeitemColumn: [
        {
          title: '合同编号',
          key: 'contractCode'
        },
        {
          title: '客户',
          key: 'advertiserName'
        },
        {
          title: '品牌',
          key: 'brandName',
          render: (h, params) => {
            return h('div', [h('p', params.row.brandName || '-')])
          }
        },
        {
          title: '合同总额',
          key: 'contractAmount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.contractAmount)
              )
            ])
          }
        },

        {
          title: '执行周期',
          key: 'startDate',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                GetCurrentSchedule(params.row.startDate, params.row.endDate)
              )
            ])
          }
        },
        {
          title: '执行金额',
          key: 'periodExecutedAmount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.periodExecutedAmount)
              )
            ])
          }
        }
      ],
      mspfeeitemColumn: [
        {
          title: '供应商',
          key: 'supplierCompanyName',
          render: (h, params) => {
            return h('div', [h('p', params.row.supplierCompanyName)])
          }
        },
        {
          title: '代理商',
          key: 'createCompanyName',
          render: (h, params) => {
            return h('div', [h('p', params.row.createCompanyName)])
          }
        },
        {
          title: '资源编号',
          key: 'resourceCode',
          render: (h, params) => {
            return h('div', [h('p', params.row.resourceCode)])
          }
        },
        {
          title: '资产',
          key: 'code',
          render: (h, params) => {
            return h('div', [h('p', params.row.assetName)])
          }
        },
        {
          title: '位置',
          key: 'position',
          render: (h, params) => {
            return h('div', [h('p', params.row.position)])
          }
        },
        {
          title: '资源类型',
          key: 'startDate',
          render: (h, params) => {
            return h('div', [h('p', params.row.resourceTypeName)])
          }
        },

        {
          title: '作业类型',
          key: 'taskTypeName',
          render: (h, params) => {
            return h('div', [h('p', params.row.taskTypeName)])
          }
        },
        {
          title: '客户',
          key: 'advertiserName'
        },
        {
          title: '品牌',
          key: 'brandName',
          render: (h, params) => {
            return h('div', [h('p', params.row.brandName || '-')])
          }
        },
        {
          title: '用途',
          key: 'adTypeName',
          render: (h, params) => {
            return h('div', [h('p', params.row.adTypeName || '-')])
          }
        },
        {
          title: '记账金额',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.amount)
              )
            ])
          }
        },
        {
          title: '单价',
          key: 'unitPrice'
        },
        {
          title: '计费数量',
          key: 'quantity'
        },
        {
          title: '作业日期',
          key: 'taskRequireFinishTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.taskRequireFinishTime)])
          }
        },
        {
          title: '作业完成时间',
          key: 'taskFinishTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.taskFinishTime)])
          }
        },
        {
          title: '质检时间',
          key: 'qualityCheckTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.qualityCheckTime)])
          }
        },
        {
          title: '记账时间',
          key: 'confirmTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.confirmTime)])
          }
        },
        {
          title: '备注',
          key: 'mark',
          render: (h, params) => {
            return h('div', [h('p', params.row.mark || '-')])
          }
        }
      ],
      feeitemQuery: {
        pageSize: 15,
        pageNumber: 1,
        feeitemType: null,
        keyword: ''
      },

      adjustfeeitemTotal: 0,
      adjustfeeitemQuery: {
        pageSize: 15,
        pageNumber: 1
      },
      adjustfeeitems: [],
      adjustfeeitemColumn: [
        {
          title: '类别',
          key: 'feeTypeName'
        },
        {
          title: '备注',
          key: 'mark'
        },
        {
          title: '金额',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.amount)
              )
            ])
          }
        },
        {
          title: '生效时间',
          key: 'chargeTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.chargeTime)])
          }
        },
        {
          title: '创建时间',
          key: 'createTime',
          render: (h, params) => {
            return h('div', [h('p', params.row.createTime)])
          }
        }
      ],
      // 罚款
      fineTotal: 0,
      fineQuery: {
        pageSize: 15,
        pageNumber: 1
      },
      fines: [],
      fineColumns: [
        { title: '罚款日期', align: 'center', key: 'penaltyDate' },
        {
          title: '罚款金额',
          align: 'center',
          key: 'amount',
          render: (h, params) => {
            // return toMoney(params.row.amount)
            return h('span', { class: 'money' }, toMoney(params.row.amount))
          }
        },
        { title: '罚款原因', align: 'center', key: 'content' },
        { title: '创建时间', align: 'center', key: 'createTime' },
        { title: '创建人员', align: 'center', key: 'createUserName' }
      ],

      summary: [],
      summaryColumns: [
        { title: '任务类型', align: 'center', key: 'feeTypeName' },
        {
          title: '不含税价',
          align: 'center',
          key: 'amountExcludeTax',
          render: (h, params) => {
            // return toMoney(params.row.amount)
            return h('span', toMoney(params.row.amountExcludeTax))
          }
        },
        {
          title: '税点',
          align: 'center',
          key: 'taxRatio',
          render: (h, params) => {
            return h('span', params.row.needTaxRatio ? (params.row.taxRatio * 100).toFixed(2) + '%' : '-')
          }
        },
        {
          title: '含税价',
          align: 'center',
          key: 'amount',
          render: (h, params) => {
            // return toMoney(params.row.amount)
            return h('span', toMoney(params.row.amount))
          }
        }
      ]
    }
  },
  created () {
    this.feeitemQuery.billId = this.billId
    this.initfeeitemColumn()
    this.initBillDetail()
    this.searchfeeitems()
    this.initAdjustfeeitems()
    this.initBillFineArray()
    this.initbillamountstructurewithtaxratio()
  },
  methods: {
    // 计费项分页变更
    feeitemChangePage (currentPage) {
      this.feeitemQuery.pageNumber = currentPage
      this.initbillList()
    },
    // 计费项页大小变更
    handlePageSizeChanged (pagesize) {
      this.feeitemQuery.pageNumber = 1
      this.feeitemQuery.pageSize = pagesize
      this.initbillList()
    },

    // 调整项分页变更
    adjustfeeitemChangePage (currentPage) {
      this.adjustfeeitemQuery.pageNumber = currentPage
      this.initAdjustfeeitems()
    },
    // 调整项页大小变更
    handleadjustfeeitemPageSizeChanged (pagesize) {
      this.adjustfeeitemQuery.pageNumber = 1
      this.adjustfeeitemQuery.pageSize = pagesize
      this.initAdjustfeeitems()
    },

    // 罚款项分页变更
    fineChangePage (currentPage) {
      this.fineQuery.pageNumber = currentPage
      this.initBillFineArray()
    },
    // 罚款项页大小变更
    handlefinePageSizeChanged (pagesize) {
      this.fineQuery.pageNumber = 1
      this.fineQuery.pageSize = pagesize
      this.initBillFineArray()
    },

    initfeeitemColumn () {
      const that = this
      if (that.feeitemType === 1) {
        that.column = that.feeitemColumn
      } else {
        that.column = that.mspfeeitemColumn
      }
    },
    formatMoney (number) {
      return toMoney(number)
    },
    // 数据求汇总
    handleSummary ({ columns, data }) {
      const needSummarykey = ['amount', 'amountExcludeTax', 'contractAmount', 'periodExecutedAmount']
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计'
          }
          return
        }
        if (needSummarykey.includes(key)) {
          const values = data.map((item) => Number(item[key]))
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[key] = {
              key,
              value: toMoney(v)
            }
          } else {
            sums[key] = {
              key,
              value: '-'
            }
          }
        } else {
          sums[key] = {
            key,
            value: '-'
          }
        }
      })

      return sums
    },
    // 加载结算单基本信息
    initBillDetail () {
      const that = this
      getbilloutline({ billId: that.billId }).then((res) => {
        that.billDetail = res
      })
    },
    // 搜索计费项
    searchfeeitems () {
      this.feeitemQuery.pageNumber = 1
      this.feeitempage = []
      this.initbillList()
    },
    // 加载所有结算项(分页)
    initbillList () {
      const that = this
      that.feeitemQuery.feeitemType = that.feeitemType
      if (that.feeitemType === 1) {
        getfeeitempage(that.feeitemQuery).then((res) => {
          that.feeitempage = res.list
          that.feeitemTotal = res.totalRow
        })
      } else {
        getmspfeeitempage(that.feeitemQuery).then((res) => {
          that.feeitempage = res.list
          that.feeitemTotal = res.totalRow
        })
      }
    },

    addFeeitems () {
      this.$refs.addFeeitemModal.showModal(
        this.feeitemType,
        this.billDetail,
        // this.feeitemType === 1
        //   ? this.billDetail.agentCompanyId
        //   : this.billDetail.supplierCompanyId,
        this.billId
      )
    },

    // 加载调整项列表
    initAdjustfeeitems () {
      const that = this
      getadjustfeeitempage({
        billId: that.billId,
        pageSize: that.adjustfeeitemQuery.pageSize,
        pageNumber: that.adjustfeeitemQuery.pageNumber
      }).then((res) => {
        that.adjustfeeitems = res.list
        that.adjustfeeitemTotal = res.totalRow
      })
    },

    // 加载结算单内所有罚款项
    initBillFineArray () {
      const that = this
      const query = {
        feeType: this.feeitemType === 1 ? 53 : 52,
        publisherId: that.publisherId,
        status: 5,
        billId: that.billId,
        pageSize: that.fineQuery.pageSize,
        pageNumber: that.fineQuery.pageNumber
      }
      if (that.feeitemType === 1) {
        query.agentCompanyId = that.billDetail.agentCompanyId
      } else if (that.feeitemType === 2) {
        query.supplierCompanyId = that.billDetail.supplierCompanyId
      }
      getPenaltyPage(query).then((res) => {
        that.fineTotal = res.totalRow
        that.fines = res.list
      })
    },
    // 加载结算单内价格汇总
    initbillamountstructurewithtaxratio () {
      const that = this
      const query = {
        billId: that.billId
      }
      getbillamountstructurewithtaxratio(query).then((res) => {
        that.summary = res
      })
    }
  },
  watch: {
    billId (val) {
      this.initBillDetail()
    }
  }
}
</script>
