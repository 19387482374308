<template>
  <div>
      <Tabs>
        <!-- <div slot="extra" class="p-b-5" v-if="task.status==0&&isAuth('ApprovalTask_Approval')"> -->
        <div slot="extra" v-if="task.status==0">
          <Button class="m-r-5" type="error" @click="deny">否决</Button>
          <Button type="success"  @click="OK">同意</Button>
        </div>

        <TabPane label="任务详情">
          <component :is="componentName" :id="relateId" :task="task" @on-checked="handleBrandChecked" @on-lowerSettlementPrice="initLowerSettlementPrice"/>
        </TabPane>
        <TabPane label="任务进度">
          <history ref="ApprovalHistory" :relate-id="relateId" :type="'cancel'" />
        </TabPane>
      </Tabs>

    <Modal v-model="showModal" width="600">
      <p slot="header" class="text-center">
        <Icon type="ios-information-circle"></Icon>
        <span v-show="pass">操作提示</span>
        <span v-show="!pass">否决理由</span>
      </p>
      <div class="text-center">
        <p v-show="pass">您将<span class="text-green text-bold"> 同意 </span>该审批任务，此操作无法撤销</p>
        <Checkbox v-model="known" v-show="showWarning">
          <span style="color:red">我已知晓 该关键字已经有客户被签约</span>
        </Checkbox>
        <i-input v-model="description" type="textarea" placeholder="请输入审批备注"></i-input>
      </div>
      <p v-if="pass && isLowerSettlementPrice" class="text-red p-t-10">当前合同执行价低于最低结算价， 按照规定必须强制输入审批备注！</p>
      <div slot="footer">
        <i-button type="warning" size="large"  v-show="approvalType===10 && pass" @click="completeTask(1)">确认并标记为待补充</i-button>
        <i-button type="primary" size="large" v-show="pass && known && contractSubmit" @click="completeTask">确认</i-button>
        <i-button type="primary" size="large" v-show="!pass" @click="completeTask">确认</i-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import History from '@/components/approval/History'
import Brand from './components/Brand'
import Contract from './components/Contract'
// import Customer from './components/Customer'
import Statement from './components/Statement'
import StatementChange from './components/StatementChange'
import ContractChange from './components/ContractChange'
import OrderScreen from './components/OrderScreen'
import UninstallScreen from './components/UninstallScreen'
import Agreement from './components/Agreement'
import Special from './components/Special'
import Fine from './components/Fine'
import Maintenance from './components/Maintenance'
import StatementAdjustment from './components/StatementAdjustment'
import transactionsettlement from './components/transactionsettlement'
import maintenancesettlement from './components/maintenancesettlement'
import EleScreen from './components/EleScreen.vue'
import Material from './components/Material'
import Business from './components/Business'
import Replacement from './components/Replacement.vue'
import ReplacementContractChange from './components/ReplacementContractChange.vue'

import LocalLifeScreen from './components/LocalLifeScreen'
import { completeTask, getTask } from '@/api/approval/approvaltask'

export default {
  components: {
    History,
    Brand,
    // Customer,
    // // Demand,
    Statement,
    StatementChange,
    ContractChange,
    OrderScreen,
    UninstallScreen,
    Agreement,
    Special,
    Fine,
    Contract,
    Maintenance,
    StatementAdjustment,
    transactionsettlement,
    maintenancesettlement,
    EleScreen,
    Material,
    Business,
    Replacement,
    LocalLifeScreen,
    ReplacementContractChange
  },
  data () {
    return {
      approvalType: '',
      relateId: '',
      description: '',
      pass: false,
      task: {
        status
      },
      showModal: false,
      componentName: '',
      known: true,
      showWarning: false,
      isLowerSettlementPrice: false // 合同低于最低折扣
    }
  },
  methods: {
    OK () {
      this.pass = true
      this.showModal = true
      this.description = ''
    },
    deny () {
      this.description = ''
      this.pass = false
      this.showModal = true
    },
    completeTask (needSupplement) {
      const para = {
        taskId: this.detailId,
        approvalResult: this.pass ? 1 : 0,
        comment: this.description,
        taskSupplementFeature: needSupplement || 0
      }
      this.$Spin.show()
      completeTask(para).then(data => {
        this.$Spin.hide()
        this.showModal = false
        this.$store.commit('set_approvalTask_pageType', 'list')
      }).catch(() => {
        this.$Spin.hide()
      })
    },
    handleBrandChecked (repeated) {
      this.showWarning = repeated
      this.known = !repeated
    },
    initLowerSettlementPrice (val) {
      this.isLowerSettlementPrice = val
    }
  },
  mounted () {
    getTask({ taskId: this.detailId }).then(res => {
      this.task = res
      this.approvalType = this.task.processtype
      this.relateId = this.task.relateId

      this.$refs.ApprovalHistory.getProcessCancelDetail(res)
      switch (this.approvalType) {
        case 3: // 合同
          this.componentName = 'Contract'
          break
        case 4:
          this.componentName = 'Customer'
          break
        case 6:
          this.componentName = 'Brand'
          break
        case 7:
          this.componentName = 'Statement'
          break
        case 8:
          this.componentName = 'ContractChange'
          break
        case 9:
          this.componentName = 'Contract'
          break
        case 10:
          this.componentName = 'OrderScreen'
          break
        case 11:
          this.componentName = 'StatementChange'
          break
        case 12:
          this.componentName = 'Agreement'
          break
        case 13:
          this.componentName = 'Special'
          break
        case 14:
          this.componentName = 'Fine'
          break
        case 15:
          this.componentName = 'Maintenance'
          break
        case 16:
          this.componentName = 'UninstallScreen'
          break
        case 17:
          this.componentName = 'Maintenance'
          break
        case 18:
          this.componentName = 'ContractChange'
          break
        case 19:
          this.componentName = 'StatementAdjustment'
          break
        case 20:
          this.componentName = 'transactionsettlement'
          break
        case 21:
          this.componentName = 'maintenancesettlement'
          break
        case 22:
          this.componentName = 'EleScreen'
          break
        case 23:
          this.componentName = 'Contract'
          break
        case 26:
          this.componentName = 'Material'
          break
        case 27:
          this.componentName = 'Business'
          break
        case 30:
          this.componentName = 'Replacement'
          break
        case 31:
          this.componentName = 'LocalLifeScreen'
          break
        case 32:
          this.componentName = 'ReplacementContractChange'
          break
        default:
          break
      }
    })
  },
  computed: {
    detailId () {
      return this.$store.state.approvalTask.detailId
    },
    contractSubmit () {
      if (this.approvalType === 3 && this.isLowerSettlementPrice) {
        return this.description.trim().length > 0
      } else {
        return true
      }
    }
  }
}
</script>

<style scoped>
.task-action {
  padding-top: 3em;
}
.task-action .action {
  padding-top: 1em;
}
</style>
