<template>
  <div> <i-table  stripe :columns="columns" :data="products"></i-table>

<Modal v-model="showServiceDetailModal" :width="400" :mask-closable="false" footer-hide>
    <p slot="header" class="workplatform-title">
      <span>服务费明细</span>
    </p>
    <i-table stripe :data="serviceDetail" :columns="serviceColumns"></i-table>
  </Modal></div>

</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
// import { DateDiff } from '@/utils/dateFormat'
export default {
  props: {
    products: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      columns: [],
      serviceDetail: [],
      serviceColumns: [
        { title: '服务费名称', key: 'serviceItemName' },
        { title: '服务费类型', key: 'typeName' },
        {
          title: '资源数量',
          key: 'serviceQuantity',
          render: (h, data) => {
            return h('div', [
              h('span', {}, data.row.serviceQuantity + data.row.serviceQuantityName)
            ])
          }
        },
        {
          title: '刊例单价',
          key: 'price',
          render: (h, data) => {
            return h('div', [
              h('span', {}, data.row.price + '/' + data.row.serviceQuantityName)
            ])
          }
        },
        {
          title: '签约数量',
          key: 'signServiceQuantity',
          render: (h, data) => {
            return h('div', [
              h('span', {}, data.row.signServiceQuantity + data.row.serviceQuantityName)
            ])
          }
        },
        {
          title: '签约价',
          key: 'signServiceAmount',
          render: (h, data) => {
            return h('div', [
              h(
                'span',
                { class: 'money' },
                this.formatMoney(data.row.signServiceAmount)
              )
            ])
          }
        }
      ],
      showServiceDetailModal: false
    }
  },
  created () {
    if (this.products[0].productKind === 3) {
      this.columns = [
        { title: '产品名称', key: 'goodName' },
        {
          title: '发布总量',
          render: (h, data) => {
            return h('span', data.row.quantity + data.row.productUnit)
          }
        },
        {
          title: '媒体费刊例',
          render: (h, data) => {
            return h('div', [
              h('span', { class: 'money' }, toMoney(data.row.totalUsePrice)),
              h(
                'span',
                '(' + data.row.usePriceDiscount + ')'
              )
            ])
            // h('span', { attrs: { class: 'money' } }, toMoney(data.row.totalUsePrice))
          }
        },
        {
          title: '结算价',
          render: (h, data) => {
            // const discount = data.row.totalUsePrice === 0 ? 0 : data.row.totalSettlementPrice / data.row.totalUsePrice
            return h('div', [
              h('span', { attrs: { class: 'money' } }, toMoney(data.row.totalSettlementPrice)),
              h('span', { attrs: { class: 'discount' } }, this.computeShowDiscount(data.row.totalUsePrice, data.row.totalSettlementPrice))

            ])
          }
        },
        {
          title: '制作费',
          render: (h, data) => {
            return h('span', { attrs: { class: 'money' } }, toMoney(data.row.producePrice))
          }
        },
        {
          title: '上刊费',
          render: (h, data) => {
            return h('span', { attrs: { class: 'money' } }, toMoney(data.row.installPrice))
          }
        }

      ]
    } else {
      this.columns = [
        { title: '产品名称', key: 'goodName' },
        {
          title: '采购数量',
          render: (h, data) => {
            return h('span', data.row.quantity)
          }
        },
        // {
        //   title: '发布总量',
        //   render: (h, data) => {
        //     return h('span', data.row.quantity * DateDiff(data.row.startDate, data.row.endDate))
        //   }
        // },
        {
          title: '媒体费刊例',
          render: (h, data) => {
            return h('div', [
              h('span', { class: 'money' }, toMoney(data.row.totalUsePrice)),
              h(
                'span',
                '(' + data.row.usePriceDiscount + ')'
              )
            ])
          }
        },
        {
          title: '结算价',
          render: (h, data) => {
            // const discount = data.row.totalUsePrice === 0 ? 0 : data.row.totalSettlementPrice / data.row.totalUsePrice
            return h('div', [
              h('span', { attrs: { class: 'money' } }, toMoney(data.row.totalSettlementPrice)),
              h('span', { attrs: { class: 'discount' } }, this.computeShowDiscount(data.row.totalUsePrice, data.row.totalSettlementPrice))

            ])
          }
        },
        {
          title: '服务费刊例价',
          render: (h, data) => {
            return h('span', { attrs: { class: 'money' } }, toMoney(data.row.totalServicePrice))
          }
        },
        {
          title: '服务费签约价',
          render: (h, data) => {
            return h('div', [
              h('span', { attrs: { class: 'money' } }, toMoney(data.row.signServicePrice)),
              h('span', { attrs: { class: 'discount' } }, this.computeShowDiscount(data.row.totalServicePrice, data.row.signServicePrice))

            ])
          }
        },
        {
          title: '操作',
          key: 'operate',
          render: (h, data) => {
            return h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.showServiceDetail(data.row.goodServiceFeeVOList)
                  }
                }
              },
              '服务费明细'
            )
          }
        }

      ]
    }
  },
  methods: {

    formatMoney (number) {
      return toMoney(number)
    },
    showServiceDetail (detail) {
      this.serviceDetail = detail
      this.showServiceDetailModal = true
    },
    computeShowDiscount (denominator, member) {
      if (denominator > 0) {
        const discount = (member / denominator) * 10

        // toFixed()方法, 四舍五入
        const showDisCount = discount.toFixed(2)
        // Math.floor()，不四舍五入 ，向下取整
        // const showDisCount = Math.floor(discount * 100) / 100

        return '(' + showDisCount + '折)'
      } else {
        return ''
      }
    }
  }

}
</script>
