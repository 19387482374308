import request from '@/utils/requestV2'
const qs = require('qs')

// 获取全部审批记录
export function allFileApproval (data) {
  return request({
    url: '/ooh-sys/v1/approval/allfileapproval',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取审批记录
export function getApprovalById (data) {
  return request({
    url: '/ooh-sys/v1/approval/getapproval',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取审批记录对应的文件
export function fileByApprovalId (data) {
  return request({
    url: '/ooh-sys/v1/approval/filebyapproval',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 撤回审批
export function cancelApproval (data) {
  return request({
    url: '/ooh-sys/v1/approval/cancelapproval',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 作废审批
export function invalidApproval (data) {
  return request({
    url: '/ooh-sys/v1/approval/invalidapproval',
    method: 'post',
    data: qs.stringify(data)
  })
}
